import { Component, OnInit, Inject } from '@angular/core';
import { VoucherGroup } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  voucherGroup: VoucherGroup;
}
@Component({
  selector: 'app-voucher-group-detail',
  templateUrl: './voucher-group-detail.component.html',
  styleUrls: ['./voucher-group-detail.component.scss'],
})
export class VoucherGroupDetailComponent implements OnInit {
  townShipRef = '/township/' + localStorage.getItem('township') + '/';
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  voucherGroup: Observable<VoucherGroup>;
  public chartScheme = { domain: ['#b1e4c8', '#7ad0a1', '#5ac288', '#46b473'] };
  public chartView = [246, 246];

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.voucherGroupDoc = this.db.doc(
      this.townShipRef + 'voucherGroups/' + this.data.voucherGroup.id
    );
    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as VoucherGroup;
        data.id = actions.payload['id'];
        if (data.totalVouchers) {
          data.statisticsChart = [
            {
              name: 'Beschikbaar',
              value:
                data.totalVouchers -
                (data.activatedVouchers ? data.activatedVouchers : 0),
            },
            {
              name: 'Geactiveerd',
              value:
                (data.activatedVouchers ? data.activatedVouchers : 0) -
                (data.claimedVouchers ? data.claimedVouchers : 0),
            },
            {
              name: 'Geclaimd',
              value:
                (data.claimedVouchers ? data.claimedVouchers : 0) -
                (data.paidVouchers ? data.paidVouchers : 0),
            },
            {
              name: 'Betaald',
              value: data.paidVouchers ? data.paidVouchers : 0,
            },
          ];
        }
        return { ...data };
      })
    );
    this.voucherGroup.subscribe((voucherGroup) => {
      console.log('voucherGroup', voucherGroup);
    });
  }
}
