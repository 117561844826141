import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Township } from 'src/app/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-sepa',
  templateUrl: './edit-sepa.component.html',
  styleUrls: ['./edit-sepa.component.scss'],
})
export class EditSepaComponent implements OnInit {
  pinForm: FormGroup;
  townshipId = localStorage.getItem('township');
  township: Township;

  constructor(
    public db: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditSepaComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.pinForm = this.fb.group({
      authenticationCode: [, [Validators.required]],
    });
  }

  async checkPin() {
    if (this.pinForm.valid) {
      const pinCode = this.pinForm.value.authenticationCode;
      this.township = (
        await this.db.doc(`/township/${this.townshipId}`).get().toPromise()
      ).data() as Township;
      console.log('township id', this.townshipId)
      const defaultPin = (
        await this.db.doc('/admin/sepa-pin').get().toPromise()
      ).data() as any;
      if (pinCode === this.township.pinCode) {
        this.dialogRef.close(true);
      } else if (pinCode === defaultPin.pin) {
        this.dialogRef.close(true);
      } else {
        this.snackBar.open('Foutieve pin invoer', 'X', {
          duration: 3000,
        });
      }
    }
  }
}
