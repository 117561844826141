import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Font } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firestore } from 'firebase';

export interface DialogData {
  file: File;
}
@Component({
  selector: 'app-select-pdf-fonts',
  templateUrl: './select-pdf-fonts.component.html',
  styleUrls: ['./select-pdf-fonts.component.scss'],
})
export class SelectPdfFontsComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  fontsForm: FormGroup;
  fontCollection: AngularFirestoreCollection<Font>;
  fonts: Observable<Font[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<SelectPdfFontsComponent>
  ) {}

  ngOnInit(): void {
    this.fontsForm = this.fb.group({
      currency: ['default', Validators.required],
      slogan: ['default', Validators.required],
      description: ['default', Validators.required],
      validUntil: ['default', Validators.required],
      terms: ['default', Validators.required],
    });
    this.fontCollection = this.db.collection<Font>(
      '/township/' + this.townshipId + '/fonts',
      (ref) => ref.orderBy('name')
    );
    this.fonts = this.fontCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Font;
          data.id = a.payload.doc['id'];

          return { ...data };
        })
      )
    );
    this.fonts.subscribe((fonts) => {
      console.log('fonts', fonts);
    });
  }

  async save() {
    console.log('this.fontsForm.value', this.fontsForm.value);
    const result = { ...this.fontsForm.value } as any;
    // Don't save default values since they would just make the function slower
    Object.keys(result).forEach((key) => {
      if (result[key] == 'default') {
        result[key] = firestore.FieldValue.delete();
      }
    });
    console.log('result', result);
    this.dialogRef.close(result);
  }
}
