import { Component, Inject, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Township } from 'src/app/interfaces';

export interface DialogData {
  mustSelect: boolean;
}
@Component({
  selector: 'app-admin-dialog',
  templateUrl: './admin-dialog.component.html',
  styleUrls: ['./admin-dialog.component.scss'],
})
export class AdminDialogComponent implements OnInit {
  allTownships: any;

  constructor(
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit() {
    const townshipRef = this.db.collection<Township>('/township/', (ref) =>
      ref.orderBy('name')
    );
    this.allTownships = townshipRef.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Township;
          data.id = a.payload.doc['id'];
          return { ...data };
        })
      )
    );
    // this.allTownships.subscribe((township) => {
    //   console.log('township', township);
    // });
    // console.log('township', this.allTownships);
  }
}
