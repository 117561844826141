import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  uploadManual: boolean;

  receiptImgFileAlreadyUploaded: boolean;
  receiptImgFileUploaded: File;
  receiptImgUrl: string;

  townshipId = localStorage.getItem("township");
  waitingResponse: boolean;

  clear: boolean;
  webcamDone = false;
  useWebcam = false;

  trigger: Subject<void> = new Subject<void>();
  webcamImage: WebcamImage = null;

  constructor(
    private dialogRef: MatDialogRef<ReceiptComponent>,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  activateManual() {
    // upload file
  }

  activateWebcam() {
    this.receiptImgFileUploaded = null;
    this.useWebcam = true;
    this.clear = true;
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event) {
    const file = event.target.files[0] as File;
    this.receiptImgFileUploaded = file;
    this.uploadManual = true;
    this.clear = true;
  }

  triggerSnapshot() {
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage) {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
      this.snackBar.open('De website heeft geen toegang tot de camera', 'X', {
        duration: 4000
      });
    }
  }

  done() {
    this.webcamDone = true;
  }
  retry() {
    this.webcamImage = null;
  }

  async save() {
    this.waitingResponse = true;
    let file;
    if (this.receiptImgFileUploaded) {
      file = this.receiptImgFileUploaded;
    } else {
      file = await (await fetch(this.webcamImage.imageAsDataUrl)).blob();
    }
    this.waitingResponse = false;
    this.dialogRef.close(file);
  }
}

// dont forget to add the receipt url to voucher saveObj