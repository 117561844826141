import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Voucher } from '../interfaces';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { MatDialog } from '@angular/material/dialog';
import { SendVoucherComponent } from '../voucher-management/send-voucher/send-voucher.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss'],
})
export class HelpdeskComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  searchForm: FormGroup;
  searching: boolean;
  vouchers: Voucher[] = [];
  displayedColumns = ['number', 'email', 'phone', 'address', 'buttons'];
  env = environment;
  // errorMessage?: string;

  // testArray: Voucher[] = [
  //   {
  //     activateDate: null,
  //     activateFromType: true,
  //     claimDate: null,
  //     distributed: true,
  //     email: 'jarikhendrikx@gmail.com',
  //     houseNumber: '15',
  //     name: null,
  //     number: 'ABC7LOM8Z',
  //     paidDate: null,
  //     phone: null,
  //     postal: '5704HB',
  //     reminderSend: false,
  //     type: null,
  //     value: 1,
  //     voucherGroupId: '0wmld4mjPjOwhyHdczlm',
  //   },
  // ];

  constructor(
    public fb: FormBuilder,
    // private fns: AngularFireFunctions,
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      number: [''],
      postal: [''],
      houseNumber: [''],
      houseNumberAddition: [''],
      email: [''],
      phone: [''],
    });

    // get all vouchers so we can filter
    this.searchForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((query) => {
        console.log('query', query);
      });

    // this.vouchers = this.testArray;
  }

  async search() {
    if (this.searching) {
      return;
    }
    this.searching = true;
    const form = this.searchForm.value;
    if (
      form.number ||
      form.postal ||
      form.houseNumber ||
      form.houseNumberAddition ||
      form.email ||
      form.phone
    ) {
      console.log('townshipId', this.townshipId);
      console.log('Some value is filled in, so searching can begin');
      // try {
      // const httpSearchVoucher = this.fns.httpsCallable('httpSearchVoucher');
      // const result = await httpSearchVoucher({
      //   townshipId: this.townshipId,
      //   number: form.number,
      //   postal: form.postal,
      //   houseNumber: form.houseNumber,
      //   email: form.email,
      //   phone: form.phone,
      // }).toPromise();
      // console.log('result', result);
      // this.vouchers = result.vouchers;
      // } catch (e) {
      // console.log(`url: ${this.env.euFunctionsUrl}/httpSearchVoucher`);
      const result = (
        (await this.http
          .post(`${this.env.euFunctionsUrl}/httpSearchVoucher`, {
            data: {
              townshipId: this.townshipId.replace(/\s/g, ''),
              number: form.number.replace(/\s/g, ''),
              postal: form.postal.replace(/\s/g, ''),
              houseNumber: form.houseNumber.replace(/\s/g, ''),
              houseNumberAddition: form.houseNumberAddition.replace(/\s/g, ''),
              email: form.email.replace(/\s/g, ''),
              phone: form.phone.replace(/\s/g, ''),
            },
          })
          .toPromise()) as any
      ).data;

      console.log('result', result);
      if (result.status !== 'done') {
        if (result.status == 'too_many_results') {
          this.snackbar.open(
            'Er zijn teveel resultaten, vul de velden specifieker in.',
            'X',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackbar.open('Er is een onbekende fout opgetreden.', 'X', {
            duration: 5000,
          });
        }
      }
      this.vouchers = result.vouchers;
      // }
      this.searching = false;
    } else {
      this.searching = false;
    }
  }

  editVoucher(voucher) {
    console.log('voucher.number', voucher.number);
    this.dialog
      .open(ManageVoucherComponent, {
        width: '373px',
        data: { type: 'edit', voucherNumber: voucher.number },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          console.log('res', res);
          if (voucher.email != res.email) {
            voucher.email = res.email;
          }
          if (voucher.phone != res.phone) {
            voucher.phone = res.phone;
          }
          if (voucher.postal != res.postal) {
            voucher.postal = res.postal;
          }
          if (voucher.houseNumber != res.houseNumber) {
            voucher.houseNumber = res.houseNumber;
          }
          if (voucher.houseNumberAddition != res.houseNumberAddition) {
            voucher.houseNumberAddition = res.houseNumberAddition;
          }
        }
      });
  }

  sendVoucher(voucher) {
    console.log('voucher.number', voucher.number);
    this.dialog.open(SendVoucherComponent, {
      width: '373px',
      data: { code: voucher.number, email: voucher.email },
    });
  }
}

// Get all vouchers
// create combineObservable and filter on query
