import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tag, User } from '../interfaces';
import { EditTagComponent } from './edit-tag/edit-tag.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  tagsCollection: AngularFirestoreCollection<Tag>;
  tags: Observable<Tag[]>;
  townShipRef: string;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        localStorage.setItem('township', userDoc.township);
        this.townShipRef = '/township/' + userDoc.township + '/';
        this.tagsCollection = this.db.collection<Tag>(
          '/township/' + userDoc.township + '/tags'
        );
        this.tags = this.tagsCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Tag;
              data.id = a.payload.doc['id'];

              return { ...data };
            })
          )
        );
        this.tags.subscribe((tags) => {
          console.log('tags', tags);
        });
      });
    });
  }
  openEditTag(tag?) {
    this.dialog.open(EditTagComponent, {
      width: '500px',
      data: { tag },
    });
  }
}
