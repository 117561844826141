import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Voucher, VoucherGroup } from 'src/app/interfaces';
import * as XLSX from 'xlsx';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { environment } from '../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { UpgradeDialogComponent } from 'src/app/nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
})
export class ActionListComponent implements OnInit {
  worksheet: any;
  vouchersFileUploaded: File;
  storeData: any;
  loading = false;

  voucherGroup: VoucherGroup;
  townshipRef = `township/${localStorage.getItem('township')}/`;

  constructor(
    private snackBar: MatSnackBar, // private currencyPipe: CurrencyPipe,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActionListComponent>
  ) {}

  ngOnInit(): void {
    this.voucherGroup = this.data.voucherGroup;
    console.log('this.voucherGroups', this.voucherGroup);
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    if (id === 'vouchers') {
      this.vouchersFileUploaded = file;
      this.readExcel();
    }
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.vouchersFileUploaded);
    console.log('this.vouchersFileUploaded', this.vouchersFileUploaded);
  }

  async exportActionList() {
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/',
      (ref) =>
        ref
          .where('voucherGroupId', '==', this.voucherGroup.id)
          .orderBy('number')
    );
    const vouchersArray = [];
    const vouchersObservable = await voucherRef.get();
    await vouchersObservable.forEach((vouchers) => {
      // console.log('vouchers', vouchers);
      vouchers.forEach((voucherDoc) => {
        const voucher = voucherDoc.data() as Voucher;
        if (!voucher.activateDate || voucher.paidDate) {
          // only export vouchers that are activated and not yet claimed'
          return;
        }
        console.log('voucher', voucher);
        const exportVoucherObj = {};
        exportVoucherObj['Vouchernummer'] = voucher.number
          ? voucher.number
          : '';
        exportVoucherObj['Waarde'] =
          voucher.value !== null ? Number(voucher.value) : '';
        exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
          ? Number(voucher.amountToPayOrg)
          : '';
        // exportVoucherObj['Image Url'] = voucher.imageUrl
        //   ? voucher.imageUrl
        //   : '';
        exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
        // exportVoucherObj['Straat'] = voucher.name ? voucher.name : '';
        exportVoucherObj['Telefoonnummer'] = voucher.phone ? voucher.phone : '';
        exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
        exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
        exportVoucherObj['Huisnummer'] = voucher.houseNumber
          ? voucher.houseNumber
          : '';
        exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
          ? voucher.houseNumberAddition
          : '';
        // exportVoucherObj['Woonplaats'] = voucher.city ? voucher.city : '';
        exportVoucherObj['Straat'] = voucher.street ? voucher.street : '';
        exportVoucherObj['Kenmerk'] = voucher.type
          ? voucher.type.toString()
          : '';
        exportVoucherObj['Activatiedatum'] = voucher.activateDate
          ? voucher.activateDate.toDate().getDate() +
            '-' +
            (voucher.activateDate.toDate().getMonth() + 1) +
            '-' +
            voucher.activateDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
          ? voucher.activateOrganisation
          : '';
        exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
          ? voucher.validUntilDate.toDate().getDate() +
            '-' +
            (voucher.validUntilDate.toDate().getMonth() + 1) +
            '-' +
            voucher.validUntilDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Geclaimd'] = voucher.claimDate
          ? voucher.claimDate.toDate().getDate() +
            '-' +
            (voucher.claimDate.toDate().getMonth() + 1) +
            '-' +
            voucher.claimDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
          ? voucher.claimOrganisation
          : '';
        exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
          ? voucher.reminderSend
          : '';
        exportVoucherObj['Wie pakt op'] = voucher.responsible
          ? voucher.responsible
          : '';
        exportVoucherObj['Contact gehad op'] = voucher.contactedOn
          ? voucher.contactedOn
          : '';
        exportVoucherObj['Afspraak gepland op'] = voucher.appointmentDate
          ? voucher.appointmentDate
          : '';
        exportVoucherObj['Afspraak tijd'] = voucher.appointmentTime
          ? voucher.appointmentTime
          : '';
        exportVoucherObj['Verstrekte materialen'] = voucher.usedMaterials
          ? voucher.usedMaterials
          : '';
        exportVoucherObj['Evt opmerkingen'] = voucher.notes
          ? voucher.notes
          : '';
        vouchersArray.push(exportVoucherObj);
      });
    });
    if (vouchersArray.length > 0) {
      console.log('vouchers', vouchersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');
      // /* save to file */
      XLSX.writeFile(
        wb,
        environment.name + '.actie-lijst.' + this.voucherGroup.name + '.xlsx'
      );
    } else {
      // Nothing to export
      this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
        duration: 5000,
      });
    }
  }
  async save() {
    console.log('this.worksheet', this.worksheet);
    this.loading = true;
    const batches = [];
    batches[0] = this.db.firestore.batch();
    let batchIndex = 0;
    let operationCounter = 0;
    let totalVouchers = 0;
    let spreadsheetError;
    let wrongVoucherNummer;
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins' && key !== '!autofilter') {
          // console.log('key', key);
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'vouchernummer':
            columnNames[key] = 'number';
            break;
          // case 'waarde':
          //   columnNames[key] = 'value';
          //   break;
          case 'wie pakt op':
            columnNames[key] = 'responsible';
            break;
          case 'contact gehad op':
            columnNames[key] = 'contactedOn';
            break;
          case 'afspraak gepland op':
            columnNames[key] = 'appointmentDate';
            break;
          case 'afspraak tijd':
            columnNames[key] = 'appointmentTime';
            break;
          case 'verstrekte materialen':
            columnNames[key] = 'usedMaterials';
            break;
          case 'evt opmerkingen':
            columnNames[key] = 'notes';
            break;
        }
      });
      delete spreadsheet[1];
      // console.log('columnNames', columnNames);
      Object.keys(spreadsheet).forEach((key) => {
        spreadsheetError = false;
        const rowObj = {} as Voucher;
        // console.log('rowObj', rowObj);
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log('colName', colName);
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // now check if current field needs extra processing, overriding rowObj
            // if (colName === 'appointmentDate' || colName === 'contactedOn') {
            //   console.log('typeof', typeof spreadsheet[key][colKey]);
            //   console.log('spreadsheet[key][colKey]', spreadsheet[key][colKey]);
            //   const dateArray = spreadsheet[key][colKey].split('-');
            //   const dateString =
            //     dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
            //   const timestamp = Date.parse(dateString);
            //   // if (date == '')
            //   let date = null;
            //   if (isNaN(timestamp) === false) {
            //     date = new Date(timestamp);
            //     date = new firestore.Timestamp(
            //       Math.floor(date.getTime() / 1000),
            //       0
            //     );
            //   } else {
            //     console.log('timestamp', timestamp);
            //   }
            //   rowObj[colName] = date;
            // }
            if (colName === 'appointmentTime') {
              const timeArray = spreadsheet[key][colKey].split(':');
              console.log('timeArray', timeArray, timeArray.length);
              if (timeArray.length !== 2) {
                console.log('time failure on this voucher:', rowObj);
                spreadsheetError = true;
              }
            }
            // set rowObj with value from spreadsheet for ALL fields
          }
        });

        console.log('rowObj', rowObj);
        // Only upload voucher if it has the required value
        if (rowObj.number && !spreadsheetError) {
          totalVouchers++;
          const voucherRef = this.db
            .collection(this.townshipRef + 'vouchers/')
            .doc(rowObj['number']);
          if (rowObj.appointmentDate && rowObj.appointmentTime) {
            try {
              const timeArray = rowObj.appointmentTime.split(':');
              const hour = Number(timeArray[0]);
              const minute = Number(timeArray[1]);
              // const appointmentDate = new Date(
              //   rowObj.appointmentDate.toDate().setHours(hour, minute)
              // );
              // console.log('appointmentDate', appointmentDate);
              // rowObj.appointmentDate = appointmentDate;
            } catch (e) {
              console.error(e);
              spreadsheetError = true;
            }
          }

          if (operationCounter >= 499) {
            batchIndex++;
            operationCounter = 0;
            batches[batchIndex] = this.db.firestore.batch();
          }

          try {
            batches[batchIndex].update(voucherRef.ref, rowObj);
            operationCounter++;
          } catch (e) {
            console.error(e);
            this.snackBar.open('Je kan geen voucher velden aanpassen', 'X', {
              duration: 4000,
            });
          }
        } else {
          spreadsheetError = true;
          console.log('spreadsheetError', spreadsheetError);
        }
      });
      // console.log('batches', batches);
      // Object.keys(batches).forEach((key) => {
      //   const currentRow = spreadsheetObj[key];
      // });
    }
    if (!spreadsheetError) {
      // Upload file
      let error = false;
      for (const batch of batches) {
        try {
          await batch.commit();
        } catch (e) {
          error = true;
          console.error(e);
          this.snackBar.open('Je kunt geen voucher velden aanpassen', 'X', {
            duration: 4000,
          });
        }
      }
      if (!error) {
        console.log('done');
        this.snackBar.open('Actielijst is succesvol geimporteerd', 'X', {
          duration: 3000,
        });
      }
      this.dialogRef.close();
    } else {
      // if (wrongVoucherNummer) {
      //   this.snackBar.open(
      //     `Er is een verkeerde datum ingevuld bij ${wrongVoucherNummer}`,
      //     'X',
      //     {
      //       duration: 7500,
      //     }
      //   );
      // } else {
      this.snackBar.open(
        'Er is iets misgegaan. Probeer het later opnieuw.',
        'X',
        {
          duration: 6000,
        }
      );
      // }
      this.loading = false;
    }
  }
}
