import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AngularFirestore } from "@angular/fire/firestore";

export interface DialogData {
  code: string;
  email: string;
}

@Component({
  selector: "app-send-voucher",
  templateUrl: "./send-voucher.component.html",
  styleUrls: ["./send-voucher.component.scss"],
})
export class SendVoucherComponent implements OnInit {
  env = environment;
  awaitingResponse = false;
  sendVoucherForm: FormGroup;
  townshipId = localStorage.getItem("township") as string;
  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SendVoucherComponent>,
    private http: HttpClient
  ) {}

  ngOnInit() {
    console.log("data", this.data);
    this.sendVoucherForm = this.fb.group({
      code: [, Validators.required],
      email: [, Validators.required],
    });
    if (this.data) {
      this.sendVoucherForm.patchValue(this.data);
    }
  }

  send() {
    if (!this.awaitingResponse) {
      this.awaitingResponse = true;
      this.http
        .post(`${environment.functionsUrl}/httpSendVoucherMail`, {
          email: this.sendVoucherForm.controls.email.value,
          code: this.sendVoucherForm.controls.code.value,
          townshipId: this.townshipId,
        })
        .subscribe((res: any) => {
          console.log("res", res);
          try {
            if (res.accepted.length > 0) {
              // mail sent.
              this.snackBar.open("Bon verstuurd", "", {
                duration: 5000,
              });
              return this.dialogRef.close();
            }
          } catch (err) {
            this.awaitingResponse = false;
            //
          }
          this.snackBar.open(
            "Bon versturen mislukt, controleer gegevens en probeer opnieuw.",
            "",
            {
              duration: 5000,
            }
          );
          this.awaitingResponse = false;
        });
    }
  }
}
