import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TownshipUser } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

export interface DialogData {
  user: TownshipUser;
}

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  newUser: boolean;
  userForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    rights: new FormControl('', [Validators.required]),
  });
  saving: boolean;

  constructor(
    public dialogRef: MatDialogRef<ManageUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    private http: HttpClient,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data.user) {
      this.newUser = false;
      this.userForm.patchValue(this.data.user);
      this.userForm.controls.email.disable();
    } else {
      this.newUser = true;
      this.userForm.addControl(
        'password',
        new FormControl('', [Validators.required])
      );
    }
  }

  async save() {
    if (this.saving || !this.userForm.valid) {
      this.userForm.markAllAsTouched();
      return;
    }
    this.saving = true;
    const form = this.userForm.value;
    if (this.newUser) {
      const requestUrl = `${environment.euFunctionsUrl}/accountManageTownshipUser`;
      const result = (
        (await this.http
          .post(requestUrl, {
            data: {
              type: 'create',
              townshipId: this.townshipId,
              email: form.email,
              password: form.password,
              rights: form.rights,
            },
          })
          .toPromise()) as any
      ).data;
      console.log('result', result);
      if (result.status == 'done') {
        this.dialogRef.close();
      } else {
        if (result.status == 'email_already_exists') {
          this.snackbar.open(
            'Er bestaat al een account op dit e-mailadres.',
            'X',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackbar.open('Er is een onbekende fout opgetreden.', 'X', {
            duration: 5000,
          });
        }
      }
      this.saving = false;
    } else {
      const userId = this.data.user.id;
      const userRef = this.db.doc(
        `township/${this.townshipId}/users/${userId}`
      );
      await userRef.update(form);
      this.dialogRef.close();
    }
  }
}
