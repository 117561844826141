import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalVoucher, ExternalVoucherGroup } from 'src/app/interfaces';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';

export interface DialogData {
  voucherGroup: ExternalVoucherGroup;
}
@Component({
  selector: 'app-manage-external-voucher-group',
  templateUrl: './manage-external-voucher-group.component.html',
  styleUrls: ['./manage-external-voucher-group.component.scss'],
})
export class ManageExternalVoucherGroupComponent implements OnInit {
  env = environment;
  saving: boolean;
  voucherGroupId: string;
  voucherGroupForm: FormGroup;
  sepaForm: FormGroup;
  newVoucherGroup = false;

  // Used for file upload
  worksheet: any;
  storeData: any;
  vouchersFileUploaded: File;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ManageExternalVoucherGroupComponent>
  ) {}

  ngOnInit(): void {
    this.voucherGroupForm = this.fb.group({
      name: [, Validators.required],
      type: [, Validators.required],
    });
    this.sepaForm = this.fb.group({
      name: [],
      bic: [],
      iban: [],
    });
    if (this.data.voucherGroup) {
      this.voucherGroupId = this.data.voucherGroup.id;
      const patchObj = { ...this.data.voucherGroup } as any;
      this.voucherGroupForm.patchValue(patchObj);
      this.sepaForm.patchValue(patchObj.sepaSettings);
      this.newVoucherGroup = false;
    } else {
      this.voucherGroupId = this.db.createId();
      this.newVoucherGroup = true;
    }
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    console.log('id', id);
    console.log('file.size', file.size);
    if (id === 'vouchers') {
      this.vouchersFileUploaded = file;
      this.readExcel();
    }
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.vouchersFileUploaded);
    console.log('this.vouchersFileUploaded', this.vouchersFileUploaded);
  }

  async save() {
    console.log('this.worksheet', this.worksheet);
    if (!this.voucherGroupForm.valid) {
      return;
    }
    this.saving = true;
    const saveObj = this.voucherGroupForm.value;
    let spreadsheetError;
    const batches = [];
    batches[0] = this.db.firestore.batch();
    let batchIndex = 0;
    let operationCounter = 0;
    let totalVouchers = 0;
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            // console.log('key', key);
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] = this.worksheet[key].w;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'vouchernummer':
            columnNames[key] = 'number';
            break;
          case 'uitgegeven':
            columnNames[key] = 'distributed';
            break;
        }
      });
      delete spreadsheet[1];
      // console.log('columnNames', columnNames);
      Object.keys(spreadsheet).forEach(async (key) => {
        const rowObj = {} as ExternalVoucher;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log('colName', colName);
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // now check if current field needs extra processing, overriding rowObj
            // if (colName === 'value' || colName === 'amountToPayOrg') {
            //   rowObj[colName] = Number(spreadsheet[key][colKey]);
            // }
            if (colName === 'distributed') {
              console.log(spreadsheet[key][colKey]);
            }
          }
        });

        if (!rowObj.distributed) {
          rowObj.distributed = null;
        } else {
          rowObj.distributed = true;
        }
        // Only upload voucher if it has the required values
        if (rowObj.number) {
          totalVouchers++;
          const voucherRef = this.db
            .collection(`externalVouchers/${this.voucherGroupId}/vouchers`)
            .doc(rowObj['number']);

          if (operationCounter == 500) {
            batchIndex++;
            operationCounter = 0;
            batches[batchIndex] = this.db.firestore.batch();
          }

          batches[batchIndex].set(voucherRef.ref, rowObj, { merge: true });
          operationCounter++;
        } else if (rowObj.number) {
          spreadsheetError = true;
        }

        if (spreadsheetError) {
          this.snackBar.open(
            'Eén of meer vouchers bevatten niet alle vereisde velden: vouchernummer.',
            'X',
            {
              duration: 5000,
            }
          );
          this.saving = false;
          return;
        }
      });
    }

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });
    const sepaSettings = this.sepaForm.value;
    if (sepaSettings.iban && sepaSettings.name) {
      saveObj.sepaSettingsComplete = true;
      saveObj.sepaSettings = {
        bic: sepaSettings.bic
          ? sepaSettings.bic
          : firestore.FieldValue.delete(),
        name: sepaSettings.name,
        iban: sepaSettings.iban,
      };
    }
    console.log('saveObj', saveObj);
    await this.db
      .collection('externalVouchers')
      .doc(this.voucherGroupId)
      .set(saveObj, { merge: true });
    console.log('batches', batches);
    batches.forEach(async (batch) => {
      // console.log('batch', batch);
      await batch.commit();
    });
    this.dialogRef.close();
  }
}
