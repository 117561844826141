import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { InlineSVGModule } from 'ng-inline-svg';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule, LANGUAGE_CODE } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from '@mat-datetimepicker/core';
// import { MatDatepickerModule } from '@angular/material-moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CustomDateAdapter } from './custom-date-adapter';

import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { VoucherGroupDetailComponent } from './voucher-management/voucher-group-detail/voucher-group-detail.component';
import { VoucherGroupEditComponent } from './voucher-management/voucher-group-edit/voucher-group-edit.component';
import { EditOrganisationComponent } from './organisation-management/edit-organisation/edit-organisation.component';
import { MarkAsPaidComponent } from './organisation-management/mark-as-paid/mark-as-paid.component';
import { SettingsComponent } from './settings/settings.component';
import { SendVoucherComponent } from './voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from './voucher-management/manage-voucher/manage-voucher.component';
import { CheckVouchersComponent } from './organisation-management/check-vouchers/check-vouchers.component';
import { CheckVoucherComponent } from './organisation-management/check-vouchers/check-voucher/check-voucher.component';
import { SubmitInvoiceComponent } from './organisation-management/check-vouchers/submit-invoice/submit-invoice.component';
import { InputPaymentreferenceComponent } from './organisation-management/input-paymentreference/input-paymentreference.component';
import { TagsComponent } from './tags/tags.component';
import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
import { UpgradeDialogComponent } from './nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { ContinueComponent } from './settings/dialog/continue-dialog/continue.component';
import { ConfirmationComponent } from './voucher-management/dialogs/confirmation-code/confirmation-code.component';
import { GenerateVouchersComponent } from './voucher-management/dialogs/generate-vouchers/generate-vouchers.component';
import { DiscountComponent } from './organisation-management/dialog/discount/discount.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/dialogs/order/order.component';
import { ForceApiSyncDialogComponent } from './settings/dialog/force-api-sync-dialog/force-api-sync-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { ReceiptComponent } from './voucher-management/dialogs/receipt/receipt.component';
import { SetupPaymentDialogComponent } from './settings/dialog/setup-payment-dialog/setup-payment-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SepaComponent } from './payment-references/dialog/sepa/sepa.component';
import { SepaConfirmationComponent } from './payment-references/dialog/sepa-confirmation/sepa-confirmation.component';
import { FileSaverModule } from 'ngx-filesaver';
import { PaymentReferencesComponent } from './payment-references/payment-references.component';
import { ActionListComponent } from './voucher-management/dialogs/action-list/action-list.component';
import { PromotionMaterialsComponent } from './promotion-materials/promotion-materials.component';
import { CompilePromoComponent } from './promotion-materials/dialogs/compile-promo/compile-promo.component';
import { AdminDialogComponent } from './dashboard-township/dialog/admin-dialog/admin-dialog.component';
import { ConfirmationDialogComponent } from './settings/dialog/confirmation-dialog/confirmation-dialog.component';
import { FontsComponent } from './fonts/fonts.component';
import { EditFontComponent } from './fonts/edit-font/edit-font.component';
import { SelectPdfFontsComponent } from './settings/dialog/select-pdf-fonts/select-pdf-fonts.component';
import { GroupLinkDialogComponent } from './settings/dialog/group-link-dialog/group-link-dialog.component';
import { ExternalVoucherManagementComponent } from './external-voucher-management/external-voucher-management.component';
import { ManageExternalVoucherGroupComponent } from './external-voucher-management/dialogs/manage-external-voucher-group/manage-external-voucher-group.component';
import { EditSepaComponent } from './organisation-management/edit-organisation/edit-sepa/edit-sepa.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UsersComponent } from './users/users.component';
import { ManageUserComponent } from './users/dialogs/manage-user/manage-user.component';
import { DeleteUserComponent } from './users/dialogs/delete-user/delete-user.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    VoucherGroupDetailComponent,
    MarkAsPaidComponent,
    SubmitInvoiceComponent,
    InputPaymentreferenceComponent,
    UpgradeDialogComponent,
    GenerateVouchersComponent,
    ReceiptComponent,
    NavBarComponent,
    DashboardTownshipComponent,
    SettingsComponent,
    VoucherManagementComponent,
    VoucherGroupEditComponent,
    OrganisationManagementComponent,
    EditOrganisationComponent,
    SendVoucherComponent,
    ManageVoucherComponent,
    CheckVouchersComponent,
    CheckVoucherComponent,
    TagsComponent,
    EditTagComponent,
    ContinueComponent,
    ConfirmationComponent,
    DiscountComponent,
    OrdersComponent,
    OrderComponent,
    ForceApiSyncDialogComponent,
    SetupPaymentDialogComponent,
    SepaComponent,
    SepaConfirmationComponent,
    PaymentReferencesComponent,
    ActionListComponent,
    PromotionMaterialsComponent,
    CompilePromoComponent,
    AdminDialogComponent,
    ConfirmationDialogComponent,
    FontsComponent,
    EditFontComponent,
    SelectPdfFontsComponent,
    GroupLinkDialogComponent,
    ExternalVoucherManagementComponent,
    ManageExternalVoucherGroupComponent,
    EditSepaComponent,
    HelpdeskComponent,
    UsersComponent,
    ManageUserComponent,
    DeleteUserComponent,
  ],
  imports: [
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatChipsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxChartsModule,
    ColorPickerModule,
    MatRadioModule,
    WebcamModule,
    MatStepperModule,
    FileSaverModule,
    NgxMaterialTimepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
  ],
  entryComponents: [
    LoginComponent,
    RegistrationComponent,
    DashboardTownshipComponent,
    SettingsComponent,
    VoucherManagementComponent,
    VoucherGroupDetailComponent,
    VoucherGroupEditComponent,
    OrganisationManagementComponent,
    EditOrganisationComponent,
    MarkAsPaidComponent,
    SendVoucherComponent,
    ManageVoucherComponent,
    CheckVouchersComponent,
    CheckVoucherComponent,
    SubmitInvoiceComponent,
    InputPaymentreferenceComponent,
    TagsComponent,
    EditTagComponent,
    UpgradeDialogComponent,
    ContinueComponent,
    ConfirmationComponent,
    GenerateVouchersComponent,
    DiscountComponent,
    OrdersComponent,
    OrderComponent,
    ForceApiSyncDialogComponent,
    ReceiptComponent,
    SetupPaymentDialogComponent,
    SepaComponent,
    SepaConfirmationComponent,
    ActionListComponent,
    PromotionMaterialsComponent,
    CompilePromoComponent,
    AdminDialogComponent,
    EditFontComponent,
    SelectPdfFontsComponent,
    GroupLinkDialogComponent,
    ManageExternalVoucherGroupComponent,
    HelpdeskComponent,
    ManageUserComponent,
    DeleteUserComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    CurrencyPipe,
    { provide: LANGUAGE_CODE, useValue: 'nl' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
