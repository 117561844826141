import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  User,
  TownshipUser,
  Township,
  Organisation,
  Address,
  Voucher,
  VoucherGroup,
  InvoiceInfo,
  AddressException,
  ExternalVoucherGroup,
} from '../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase, { analytics, firestore } from 'firebase';
import { Router } from '@angular/router';
import { ContinueComponent } from './dialog/continue-dialog/continue.component';
import { environment } from 'src/environments/environment';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { ForceApiSyncDialogComponent } from './dialog/force-api-sync-dialog/force-api-sync-dialog.component';
import { SetupPaymentDialogComponent } from './dialog/setup-payment-dialog/setup-payment-dialog.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { SelectPdfFontsComponent } from './dialog/select-pdf-fonts/select-pdf-fonts.component';
import { GroupLinkDialogComponent } from './dialog/group-link-dialog/group-link-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('primary', { static: false }) primary: ElementRef;
  @ViewChild('accent', { static: false }) accent: ElementRef;
  env = environment;
  settingsForm: FormGroup;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  townshipDoc: AngularFirestoreDocument<Township>;
  township: Township;
  townshipId: string;
  townshipUserDoc: AngularFirestoreDocument<TownshipUser>;
  townshipUser: Observable<TownshipUser>;
  accentToggle: boolean;
  primaryToggle: boolean;
  // Used for file upload
  // storeData: any;
  addressEmptyOnLoad: boolean;
  addressFileUploaded: File;
  addressWorksheet: any;
  postalsEmptyOnLoad = true;
  postalsFileUploaded: File;
  postalsWorksheet: any;
  saving: boolean;
  sisowProfileId: string;
  externalVoucherGroups: Observable<ExternalVoucherGroup[]>;
  externalVoucherGroupsArray: ExternalVoucherGroup[];

  formChanged: boolean;
  deleteHeaderImg: boolean;

  invoiceData: {};
  checkboxCheck = false;

  prefixes: any[] = [
    { name: 'Lokale Bon', db: 'lokale' },
    { name: 'Groene Bon', db: 'groene' },
    { name: 'Toegangsbon', db: 'toegangs' },
    { name: 'Duurzaam Wonen Bon', db: 'duurzaamwonen' },
  ];

  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;

  invoicePdfFileAlreadyUploaded: boolean;
  invoicePdfFileUploaded: File;
  invoicePdfUrl: string;
  deleteInvoicePdf: boolean;

  voucherPdfFileAlreadyUploaded: boolean;
  voucherPdfFileUploaded: File;
  voucherPdfUrl: string;
  voucherPdfFonts: any;
  deleteVoucherPdf: boolean;

  deleteAddresses = false;
  addressCheckDbl: boolean;

  addressExceptionFileAlreadyUploaded: boolean;
  addressExceptionFileUploaded: File;
  addressExceptionWorksheet: any;
  deleteAddressesExceptions: boolean;
  pinCode: string;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    private http: HttpClient
  ) {}
  rgbToHex(rgb) {
    const rgbArray = rgb.substring(4, rgb.length - 1).split(', ');
    console.log(rgbArray);
    let r = Number(rgbArray[0]).toString(16);
    let g = Number(rgbArray[1]).toString(16);
    let b = Number(rgbArray[2]).toString(16);
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;

    // return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      console.log('this.primary.nativeElement', this.primary.nativeElement);
      if (!this.settingsForm.value.primaryColor) {
        this.settingsForm.controls.primaryColor.setValue(
          this.rgbToHex(getComputedStyle(this.primary.nativeElement).color)
        );
      }
      if (!this.settingsForm.value.accentColor) {
        this.settingsForm.controls.accentColor.setValue(
          this.rgbToHex(getComputedStyle(this.accent.nativeElement).color)
        );
      }
    }, 500);
  }

  async ngOnInit() {
    console.log('prefixes', this.prefixes);
    this.settingsForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
      termsUrl: new FormControl('', [Validators.required]),
      externalVoucherGroupId: new FormControl(''),
      municipalitys: new FormControl(),
      voucherPrefix: new FormControl('', [Validators.required]),
      prefix: new FormControl('', [Validators.required]),
      sisowProfileId: new FormControl(''),
      pinCode: new FormControl().disable,
      primaryColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
      accentColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
    });
    this.externalVoucherGroups = this.db
      .collection<ExternalVoucherGroup>(`externalVouchers`)
      .valueChanges({ idField: 'id' });
    this.externalVoucherGroups.subscribe((groups) => {
      this.externalVoucherGroupsArray = groups;
      console.log('externalVoucherGroups', groups);
    });
    const user = await this.afAuth.user.subscribe((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach(async (userDoc) => {
        // console.log('userDoc.voucherPrefix', );
        this.townshipId = userDoc.township;
        console.log('townshipId', this.townshipId);
        this.townshipDoc = this.db.doc<Township>(
          'township/' + userDoc.township
        );
        this.townshipUserDoc = this.db.doc<TownshipUser>(
          'township/' + userDoc.township + '/users/' + user.uid
        );
        this.township = (await this.townshipDoc.ref.get()).data() as Township;
        this.addressEmptyOnLoad = (
          await this.townshipDoc.collection('addresses').ref.limit(1).get()
        ).empty;
        console.log('this.township', this.township);
        if (this.township.postals) {
          this.postalsEmptyOnLoad = false;
        }
        if (this.township.useHeaderImage) {
          this.headerImgFileAlreadyUploaded = true;
          this.headerImgUrl = this.township.headerImageUrl;
        }
        if (this.township.useInvoicePdf) {
          this.invoicePdfFileAlreadyUploaded = true;
          this.invoicePdfUrl = this.township.invoicePdfUrl;
        }
        if (this.township.useVoucherPdf) {
          this.voucherPdfFileAlreadyUploaded = true;
          this.voucherPdfUrl = this.township.voucherPdfUrl;
        }
        if (this.township.invoiceInfo && this.township.sisow) {
          this.checkboxCheck = true;
        }

        if (this.township.checkAddressesDbl) {
          this.addressCheckDbl = true;
          if (this.township.useAddressException) {
            this.addressExceptionFileAlreadyUploaded = true;
          }
        }
        // if (this.township.checkAddresses) {
        //   this.addressCheck = true;
        // }
        const patchObj = this.township as any;
        if (this.township.municipalitys) {
          patchObj.municipalitys = this.township.municipalitys.toString();
        }
        this.settingsForm.patchValue(patchObj);
      });
    });
    this.settingsForm.valueChanges.subscribe((value) => {
      if (this.settingsForm.dirty) {
        this.formChanged = true;
      }
    });
    const township = (
      await this.db
        .doc(`township/${localStorage.getItem('township')}`)
        .get()
        .toPromise()
    ).data() as Township;
    console.log('Township', township);
    this.pinCode = township.pinCode;
    if (!this.pinCode) {
      const defaultPin = (
        await this.db.doc(`admin/sepa-pin`).get().toPromise()
      ).data() as any;
      this.pinCode = defaultPin.pin;
      console.log('Default pincode', this.pinCode);
    }
    const patchObj = township as any;
    if (township.municipalitys) {
      patchObj.municipalitys = township.municipalitys.toString();
    }
    this.settingsForm.patchValue(patchObj);
    this.settingsForm.controls.pinCode.setValue(this.pinCode);
  }

  async exportAllVouchers() {
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/'
    );
    const voucherGroupsRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/voucherGroups/'
    );
    const voucherGroupsObservable = voucherGroupsRef.get();
    const vouchersArray = [];
    const vouchersObservable = voucherRef.get();
    voucherGroupsObservable.forEach((voucherGroups) => {
      console.log('voucherGroups', voucherGroups);
      vouchersObservable.forEach((vouchers) => {
        // console.log('vouchers', vouchers);
        vouchers.forEach(async (voucherDoc) => {
          const voucher = voucherDoc.data() as Voucher;
          let voucherGroupName = '';
          if (voucher.voucherGroupId) {
            const voucherGroupDoc = voucherGroups.docs.find(
              (voucherGroup) => voucherGroup.id === voucher.voucherGroupId
            );
            if (voucherGroupDoc) {
              voucherGroupName = (voucherGroupDoc.data() as VoucherGroup).name;
            }
          } else {
            console.log('voucher has no voucherGroupId', voucher);
          }

          const exportVoucherObj = {};
          exportVoucherObj['Vouchernummer'] = voucher.number
            ? voucher.number
            : '';
          exportVoucherObj['Waarde'] =
            voucher.value !== null ? Number(voucher.value) : '';
          exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
            ? Number(voucher.amountToPayOrg)
            : '';
          // exportVoucherObj['Image Url'] = voucher.imageUrl
          //   ? voucher.imageUrl
          //   : '';
          exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
          exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
          exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
          exportVoucherObj['Huisnummer'] = voucher.houseNumber
            ? voucher.houseNumber
            : '';
          exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
            ? voucher.houseNumberAddition
            : '';
          exportVoucherObj['Kenmerk'] = voucher.type
            ? voucher.type.toString()
            : '';
          exportVoucherObj['Uitgegeven'] = voucher.distributed ? 'WAAR' : '';
          exportVoucherObj['Activatiedatum'] = voucher.activateDate
            ? voucher.activateDate.toDate().getDate() +
              '-' +
              (voucher.activateDate.toDate().getMonth() + 1) +
              '-' +
              voucher.activateDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
            ? voucher.activateOrganisation
            : '';
          exportVoucherObj['Claimdatum'] = voucher.claimDate
            ? voucher.claimDate.toDate().getDate() +
              '-' +
              (voucher.claimDate.toDate().getMonth() + 1) +
              '-' +
              voucher.claimDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
            ? voucher.claimOrganisation
            : '';
          exportVoucherObj['Betaaldatum'] = voucher.paidDate
            ? voucher.paidDate.toDate().getDate() +
              '-' +
              (voucher.paidDate.toDate().getMonth() + 1) +
              '-' +
              voucher.paidDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Betalingskenmerk'] = voucher.paymentReference
            ? voucher.paymentReference
            : '';
          exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
            ? voucher.validUntilDate.toDate().getDate() +
              '-' +
              (voucher.validUntilDate.toDate().getMonth() + 1) +
              '-' +
              voucher.validUntilDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
            ? 'WAAR'
            : '';
          exportVoucherObj['Bongroep'] = voucherGroupName
            ? voucherGroupName
            : '';
          vouchersArray.push(exportVoucherObj);

          if (vouchers.size === vouchersArray.length) {
            if (vouchersArray.length > 0) {
              console.log('vouchers', vouchersArray);
              const ws: XLSX.WorkSheet =
                XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

              // /* save to file */
              XLSX.writeFile(wb, this.env.name + '.' + 'Alle Bonnen' + '.xlsx');
            } else {
              // Nothing to export
              this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
                duration: 5000,
              });
            }
          }
          // });
        });
      });
    });
  }
  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.settingsForm.value } as Township;

    let municipalitys: string[];
    if (this.settingsForm.value.municipalitys) {
      if (this.postalsWorksheet || this.addressWorksheet) {
        this.saving = false;
        return this.snackBar.open(
          "De postcode functionaliteit kan niet tegelijk gebruikt worden met de 'Gemeente(s) voor adrescontrole' ",
          'X',
          {
            duration: 5000,
          }
        );
      }
      municipalitys = this.settingsForm.value.municipalitys.split(',');
      for (let i = 0; i < municipalitys.length; i++) {
        const municipality = municipalitys[i];
        municipalitys[i] = municipality.trim();
      }
      // municipalitys.forEach((municipality) => {
      //   municipality = municipality.trim();
      //   console.log('municipality', municipality);
      // });
      console.log('municipalitys', municipalitys);
      saveObj.municipalitys = municipalitys;
    } else {
      saveObj.municipalitys = null;
    }

    if (this.postalsWorksheet || this.addressWorksheet) {
      if (this.settingsForm.value.municipalitys) {
        return;
      }
    }

    if (saveObj.prefix.length === 3) {
      // console.log('voucher prefix isnt good enough');
      if (
        !this.urlCheck(saveObj.url, 'url') ||
        !this.urlCheck(saveObj.termsUrl, 'terms')
      ) {
        return;
      }

      if (this.invoiceData) {
        saveObj.invoiceInfo = this.invoiceData as InvoiceInfo;
      }
      // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
      Object.keys(saveObj).forEach((key) => {
        if (saveObj[key] == null) {
          saveObj[key] = firestore.FieldValue.delete();
        }
      });
      // console.log('worksheet', this.addressWorksheet);

      if (this.headerImgFileUploaded) {
        const filePath = `${this.townshipId}/images/${this.townshipId}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }

      if (this.invoicePdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/factuur.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.invoicePdfFileUploaded
        );
        saveObj.invoicePdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useInvoicePdf = true;
      }

      if (this.voucherPdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/bon-template.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.voucherPdfFileUploaded
        );
        saveObj.voucherPdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useVoucherPdf = true;
        if (this.voucherPdfFonts) {
          saveObj.voucherPdfFonts = this.voucherPdfFonts;
        }
      }

      if (this.deleteHeaderImg) {
        this.deleteFile('headerImg', 'img');
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }
      if (this.deleteInvoicePdf) {
        this.deleteFile('invoicePdf', 'pdf');
        saveObj.invoicePdfUrl = '';
        saveObj.useInvoicePdf = false;
      }
      if (this.deleteVoucherPdf) {
        this.deleteFile('voucherPdf', 'pdf');
        saveObj.voucherPdfUrl = '';
        saveObj.useVoucherPdf = false;
      }
      if (this.deleteAddressesExceptions) {
        saveObj.useAddressException = false;
        // there is no functionality to delete a whole collection
        // need to retrieve all docs and then delete
        const addressExceptions = this.db
          .collection(`township/${this.townshipId}/addressExceptions`)
          .get();
        addressExceptions.subscribe(async (addressException: any) => {
          addressException.forEach(async (adres) => {
            await this.db.doc(adres.ref.path).delete();
          });
        });
        console.log('addressExceptions', addressExceptions);
      }
      // if (this.sisowProfileId) {
      //   // console.log('profileId', this.sisowProfileId);
      //   saveObj.sisowProfile = this.sisowProfileId;
      // }

      saveObj.checkAddressesDbl = this.addressCheckDbl
        ? this.addressCheckDbl
        : false;

      const batches = [];
      let batchIndex = 0;
      let operationCounter = 0;
      let addressesSpreadsheetError;
      if (this.addressWorksheet) {
        const spreadsheet = {};
        Object.keys(this.addressWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.addressWorksheet[key].w;
            }
          } catch (error) {
            console.log('key with error:', key);
            console.error(error);
          }
        });
        // console.log('spreadsheet', spreadsheet);
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          // console.log('key', key);
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode':
              columnNames[key] = 'postal';
              break;
            case 'huisnummer':
              columnNames[key] = 'houseNumber';
              break;
            case 'toevoeging':
              columnNames[key] = 'houseNumberAddition';
              break;
            case 'type':
              columnNames[key] = 'type';
              break;
            case 'straatnaam':
              columnNames[key] = 'street';
              break;
            case 'woonplaats':
              columnNames[key] = 'place';
              break;
          }
          // console.log('columnNames', columnNames);
        });
        delete spreadsheet[1];
        // console.log('columnNames', columnNames);
        batches[0] = this.db.firestore.batch();
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as any;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            // console.log('spreadsheet[key][colKey]', spreadsheet[key][colKey]);
            // console.log('colName', colName);
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
              // set rowObj with value from spreadsheet for ALL fields
              if (colName === 'postal') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toUpperCase()
                  .replace(/ /g, '');
              }
              if (colName === 'houseNumberAddition') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toLowerCase()
                  .replace(/ /g, '');
              }
            }
          });
          // console.log('rowObj', rowObj.type);
          let newtypes = [];
          if (rowObj.type && rowObj.type.includes(',')) {
            newtypes = rowObj.type
              .split(',')
              .map((item: string) => item.trim().toLowerCase());
          } else if (rowObj.type && !rowObj.type.includes(',')) {
            // cannot use else because of empty excel field
            newtypes = [rowObj.type.toString().toLowerCase()];
          }

          if (newtypes && newtypes.length > 0) {
            newtypes.forEach(async (type) => {
              const addresTypeRef = this.db.doc(
                `township/${this.townshipId}/addresTypes/${type}`
              );
              if (operationCounter == 500) {
                batchIndex++;
                operationCounter = 0;
                batches[batchIndex] = this.db.firestore.batch();
              }
              batches[batchIndex].set(
                addresTypeRef.ref,
                { type: type },
                { merge: true }
              );
              operationCounter++;
            });
          }
          if (newtypes.length > 0) {
            rowObj.type = newtypes;
          } else {
            rowObj.type = firestore.FieldValue.delete();
          }
          // Only upload voucher if it has both of the required values
          if (rowObj.postal && rowObj.houseNumber) {
            const addressString =
              rowObj.postal +
              rowObj.houseNumber +
              (rowObj.houseNumberAddition ? rowObj.houseNumberAddition : '');
            // console.log('addressString', addressString);
            const ref = this.db
              .collection(this.townshipDoc.ref.path + '/addresses/')
              .doc(addressString);
            // console.log('ref', ref.ref.path);
            if (operationCounter == 500) {
              batchIndex++;
              operationCounter = 0;
              batches[batchIndex] = this.db.firestore.batch();
            }
            console.log('rowObj', rowObj);
            batches[batchIndex].set(ref.ref, rowObj, { merge: true });
            operationCounter++;
          } else {
            addressesSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
      }

      let postalsSpreadsheetError;
      if (this.postalsWorksheet) {
        const postals = [];
        const spreadsheet = {};
        Object.keys(this.postalsWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.postalsWorksheet[key].w;
            }
          } catch (error) {
            console.log('key with error:', key);
            console.error(error);
          }
        });
        // console.log('spreadsheet', spreadsheet);
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          // console.log('key', key);
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode cijfers':
              columnNames[key] = 'postal';
              break;
          }
        });
        delete spreadsheet[1];
        // console.log('columnNames', columnNames);
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as Address;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            // console.log(spreadsheet[key][colKey]);
            // console.log('colName', colName);
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
            }
          });
          // console.log('rowObj', rowObj);

          // Only upload voucher if it has both of the required values
          if (rowObj.postal && rowObj.postal.length === 4) {
            postals.push(rowObj.postal);
          } else {
            postalsSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
        saveObj.postals = postals;
      }
      let addressExceptionError;
      operationCounter = 0;
      batchIndex += 1;
      if (this.addressExceptionWorksheet) {
        const addressExcpetion = [];
        const spreadsheet = {};
        Object.keys(this.addressExceptionWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.addressExceptionWorksheet[key].w;
            }
          } catch (error) {
            console.log('key with error:', key);
            console.error(error);
          }
        });
        // console.log('spreadsheet', spreadsheet);
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          // console.log('key', key);
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode':
              columnNames[key] = 'postal';
              break;
            case 'huisnummer':
              columnNames[key] = 'houseNumber';
              break;
            case 'toevoeging':
              columnNames[key] = 'houseNumberAddition';
              break;
            case 'kenmerken':
              columnNames[key] = 'type';
              break;
          }
        });
        delete spreadsheet[1];
        // console.log('columnNames', columnNames);
        batches[batchIndex] = this.db.firestore.batch();
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as Address;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            // console.log(spreadsheet[key][colKey]);
            // console.log('colName', colName);
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
            }
          });
          console.log('rowObj', rowObj);

          // Only upload voucher if it has both of the required values
          if (
            rowObj.postal &&
            rowObj.postal.length === 6 &&
            rowObj.houseNumber
          ) {
            if (rowObj.type) {
              const types = rowObj.type as any; // redeclare var because .split() is not a function on string[]
              console;
              rowObj.type = types.split(','); //Use split() instead of slice()
            }

            if (operationCounter == 500) {
              batchIndex++;
              operationCounter = 0;
              batches[batchIndex] = this.db.firestore.batch();
            }

            rowObj.postal = rowObj.postal.replace(' ', ''); // remove whitespaces
            rowObj.houseNumberAddition = rowObj.houseNumberAddition
              ? rowObj.houseNumberAddition
              : '';
            const docId =
              rowObj.postal + rowObj.houseNumber + rowObj.houseNumberAddition;

            // houseNumber is a string because then we can catch the error if an housenumber addition is typed in by mistake (or no mistake)
            batches[batchIndex].set(
              this.db.doc(
                `township/${this.townshipId}/addressExceptions/${docId}`
              ).ref,
              rowObj,
              { merge: true }
            );
            operationCounter++;

            addressExcpetion.push(rowObj);
          } else {
            addressExceptionError = true;
          }
          console.log('rowObj', rowObj);
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
        saveObj.useAddressException = true;
      }
      if (
        !addressesSpreadsheetError ||
        !postalsSpreadsheetError ||
        !addressExceptionError
      ) {
        console.log('this.townshipDoc', this.townshipDoc.ref.path);
        console.log('saveObj', saveObj);
        if (saveObj.voucherPrefix === 'groene') {
          saveObj.voucherImg = this.env.prefixImgGroene;
        } else {
          saveObj.voucherImg = this.env.prefixImgLokale;
        }
        await this.townshipDoc.set(saveObj, { merge: true });
        if (saveObj.externalVoucherGroupId) {
          let externalVoucherGroup;
          if (this.externalVoucherGroups && this.externalVoucherGroupsArray) {
            externalVoucherGroup = this.externalVoucherGroupsArray.find(
              (voucherGroup) => {
                return voucherGroup.id === saveObj.externalVoucherGroupId;
              }
            );
          }
          if (externalVoucherGroup) {
            const name = externalVoucherGroup.name
              ? externalVoucherGroup.name
              : 'Externe Bongroep';
            const orgObj = {
              name,
              lowercaseName: name.toLowerCase(),
              externalVouchersOrg: true,
            } as Organisation;

            if (externalVoucherGroup.sepaSettingsComplete) {
              orgObj.sepaSettingsComplete = true;
              orgObj.sepaSettings = externalVoucherGroup.sepaSettings;
            }
            await this.townshipDoc
              .collection('organisations')
              .doc(saveObj.externalVoucherGroupId)
              .set(orgObj, { merge: true });
          }
        }
        batches.forEach(async (batch) => {
          console.log(batch);
          await batch.commit();
        });
        if (this.deleteAddresses) {
          const requestUrl = `${environment.functionsUrl}/httpDeleteAddresses`;
          // console.log('requestUrl', requestUrl);
          let res: Observable<any>;
          const postData = {
            townshipId: this.townshipId,
          };
          res = this.http.post(requestUrl, postData);
          res.subscribe(async (result) => {
            console.log('result', JSON.stringify(result));
            if (result.message === 'succeed') {
              this.router.navigateByUrl('/dashboard-township');
            } else {
              this.snackBar.open('Oops er iets verkeerds gegaan', 'X', {
                duration: 4000,
              });
            }
          });
        } else {
          this.router.navigateByUrl('/dashboard-township');
        }
      } else {
        this.saving = false;
        if (!addressesSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer adressen bevatten niet alle vereisde velden: postcode en huisnummer.',
            'X',
            {
              duration: 3000,
            }
          );
        } else if (!postalsSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer postcodecijfers bevatten niet alle vereisde velden: postcodecijfers (4 karakters).',
            'X',
            {
              duration: 3000,
            }
          );
        } else if (!addressExceptionError) {
          this.snackBar.open(
            'De adres uitzonderingen zijn niet in het juiste format.',
            'X',
            {
              duration: 3000,
            }
          );
        } else {
          console.log('something bad happened');
        }
      }
    } else {
      this.settingsForm.controls['prefix'].setErrors({ incorrect: true });
      this.saving = false;
    }
  }
  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event, type) {
    this.formChanged = true;
    const file = event.target.files[0] as File;
    if (type === 'postals') {
      this.postalsFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'address') {
      this.addressFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    } else if (type === 'invoicePdf') {
      this.invoicePdfFileUploaded = file;
      console.log('pdf', this.invoicePdfFileUploaded);
    } else if (type === 'voucherPdf') {
      const dialogRef = this.dialog.open(SelectPdfFontsComponent, {
        width: '373px',
        data: { file: file },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('The dialog was closed with result', result);
        if (result) {
          this.voucherPdfFonts = result;
          this.voucherPdfFileUploaded = file;
          console.log('pdf', this.voucherPdfFileUploaded);
        }
      });
    } else if (type === 'addressException') {
      this.addressExceptionFileUploaded = event.target.files[0];
      this.readExcel(type);
    }
  }
  readExcel(type) {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      if (type === 'postals') {
        this.postalsWorksheet = workbook.Sheets[firstSheetName];
        console.log('this.postalsWorksheet', this.postalsWorksheet);
      } else if (type === 'address') {
        this.addressWorksheet = workbook.Sheets[firstSheetName];
        console.log('this.addressWorksheet', this.addressWorksheet);
      } else if (type === 'addressException') {
        this.addressExceptionWorksheet = workbook.Sheets[firstSheetName];
        console.log('this.addressWorksheet', this.addressWorksheet);
      }
    };
    if (type === 'postals') {
      readFile.readAsArrayBuffer(this.postalsFileUploaded);
    } else if (type === 'address') {
      readFile.readAsArrayBuffer(this.addressFileUploaded);
    } else if (type === 'addressException') {
      readFile.readAsArrayBuffer(this.addressExceptionFileUploaded);
    }
  }
  async exportAddresses() {
    const exportArray = [];

    const addressesRef = this.db.collection(
      this.townshipDoc.ref.path + '/addresses/'
    );
    const addressesObservable = await addressesRef.get();
    await addressesObservable.forEach((addresses) => {
      addresses.forEach((addressDoc) => {
        const exportObj = {};
        const address = addressDoc.data() as Address;
        console.log('address', address);
        if (address.houseNumber.includes(' ')) {
          return;
        }
        exportObj['Postcode'] = address.postal;
        exportObj['Huisnummer'] = address.houseNumber;
        exportObj['Toevoeging'] = address.houseNumberAddition;
        exportObj['Type'] = address.type ? address.type.join(', ') : '';
        exportObj['Straatnaam'] = address.street ? address.street : '';
        exportObj['Woonplaats'] = address.place ? address.place : '';
        exportArray.push(exportObj);
      });
    });
    if (exportArray.length === 0) {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Type: '',
        Straatnaam: '',
        Woonplaats: '',
      });
    }
    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Addressenlijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Addressenlijst' + '.xlsx');
  }
  async exportPostals() {
    const exportArray = [];
    if (this.township.postals) {
      this.township.postals.forEach((postal) => {
        const exportObj = {};
        exportObj['Postcode cijfers'] = postal;
        exportArray.push(exportObj);
      });
    } else {
      exportArray.push({
        'Postcode cijfers': '',
      });
    }
    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Postcodelijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Postcodelijst' + '.xlsx');
  }
  async exportExceptionalAdresses() {
    const exportArray = [];
    if (this.township.useAddressException) {
      // this.township.postals.forEach((postal) => {
      //   exportObj['Postcode cijfers'] = postal;
      //   exportArray.push(exportObj);
      // });
      const allAdressesExceptions = await this.db
        .collection(`township/${this.townshipId}/addressExceptions`)
        .get()
        .toPromise();
      console.log('allAdressesExceptions', allAdressesExceptions);

      // allAdresses.subscribe(async (addressException: any) => {
      //   console.log('addressException', addressException);
      allAdressesExceptions.forEach(async (adres) => {
        const exportObj = {}; // need to reassign var because otherwise order in xlsx is wrong
        const addressData = adres.data() as AddressException;
        exportObj['Postcode'] = addressData.postal;
        exportObj['Huisnummer'] = addressData.houseNumber;
        exportObj['Toevoeging'] = addressData.houseNumberAddition
          ? addressData.houseNumberAddition
          : '';
        exportObj['Kenmerken'] = addressData.type
          ? addressData.type.join()
          : '';
        exportArray.push(exportObj);
      });
      // });
    } else {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Kenmerken: '',
      });
    }

    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Uitzonderingen adressen');

    // /* save to file */
    XLSX.writeFile(
      wb,
      this.env.name + '.' + 'Uitzonderingen adressen' + '.xlsx'
    );
  }

  navigateTo(url) {
    if (this.formChanged) {
      // show modal
      const dialogRef = this.dialog.open(ContinueComponent, {
        width: '373px',
        // data: { type },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.router.navigateByUrl(url);
        }
      });
    } else {
      this.router.navigateByUrl(url);
    }
  }
  colorChanged(color, colorController) {
    colorController.setValue(color);
  }
  urlCheck(saveObj, controller) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      if (controller === 'terms') {
        this.settingsForm.controls.termsUrl.setErrors({
          notMatched: true,
        });
      } else {
        this.settingsForm.controls.url.setErrors({
          notMatched: true,
        });
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
  copyUrl(type?): void {
    let requestSaldoUrl = false;
    let resendVouchersUrl = false;
    if (!type) {
      type = '';
    } else {
      if (type === 'saldo') {
        requestSaldoUrl = true;
      } else if (type === 'resend-voucher') {
        resendVouchersUrl = true;
        type = '';
      } else {
        type = type + '.';
      }
    }
    const el = document.createElement('textarea');
    let domain = this.township.voucherPrefix;
    console.log('domain', domain);
    switch (domain) {
      case 'duurzaamwonen':
        domain = 'duurzaamwonenbon';
        break;
      case 'lokale':
        domain = 'lokalebon';
        break;
      case 'groene':
        domain = 'groenebon';
        break;
    }
    console.log('domain', domain);
    if (this.env.production && !requestSaldoUrl && !resendVouchersUrl) {
      el.value = `https://${type}frame.${domain}.nl/request/${localStorage.getItem(
        'township'
      )}/digital`;
    } else if (!this.env.production && !requestSaldoUrl && !resendVouchersUrl) {
      el.value = `https://dev.${type}frame.${domain}.nl/request/${localStorage.getItem(
        'township'
      )}/digital`;
    } else if (!this.env.production && !requestSaldoUrl && resendVouchersUrl) {
      el.value = `https://dev.${type}frame.lokalebon.nl/resend-voucher/${localStorage.getItem(
        'township'
      )}`;
    } else if (this.env.production && !requestSaldoUrl && resendVouchersUrl) {
      el.value = `https://${type}frame.${domain}.nl/resend-voucher/${localStorage.getItem(
        'township'
      )}`;
    }
    if (this.env.production && requestSaldoUrl && !resendVouchersUrl) {
      el.value = `https://frame.${domain}.nl/saldo-checker/${localStorage.getItem(
        'township'
      )}`;
    } else if (!this.env.production && requestSaldoUrl && !resendVouchersUrl) {
      el.value = `https://dev.frame.lokalebon.nl/saldo-checker/${localStorage.getItem(
        'township'
      )}`;
    } else if (!this.env.production && requestSaldoUrl && resendVouchersUrl) {
      el.value = `https://dev.frame.lokalebon.nl/resend-voucher/${localStorage.getItem(
        'township'
      )}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }
  async openFile(type: string): Promise<any> {
    if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    } else if (type === 'invoicePdf') {
      if (this.invoicePdfFileAlreadyUploaded) {
        window.open(this.invoicePdfUrl);
      }
    } else if (type === 'voucherPdf') {
      if (this.voucherPdfFileAlreadyUploaded) {
        window.open(this.voucherPdfUrl);
      }
    }
  }

  setDelete(type: string): void {
    console.log('type', type);
    let name = '';

    if (type === 'headerImg') {
      this.deleteHeaderImg = true;
      name = 'E-mail header';
    } else if (type === 'invoicePdf') {
      name = 'PDF';
      this.deleteInvoicePdf = true;
    } else if (type === 'voucherPdf') {
      name = 'PDF';
      this.deleteVoucherPdf = true;
    } else if (type === 'addressException') {
      name = 'Excel';
      this.deleteAddressesExceptions = true;
    }
    this.snackBar.open(
      `Druk op opslaan om de ${name} definitief te verwijderen`,
      'X',
      {
        duration: 3500,
      }
    );
  }

  deleteFile(type: string, extension: string): void {
    let segment;
    // let extension = '';
    let fileName;
    if (type === 'pdf') {
      segment = 'pdf-templates';
      // extension = '.pdf';
      fileName = this.townshipId;
    } else if (type === 'headerImg') {
      segment = 'images';
      fileName = this.townshipId;
    } else if (type === 'invoicePdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'factuur.pdf';
    } else if (type === 'voucherPdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'bon.pdf';
    }
    const name = extension === 'pdf' ? 'PDF' : 'E-mail header';
    const fileUrl = `${this.townshipId}/${segment}/${fileName}`;

    console.log('fileUrl', fileUrl);
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      // this.snackBar.open(`${name} verwijderd`, 'X', {
      //   duration: 5000
      // });
    });
  }

  async openSetupPaymentDialog() {
    const dialogRef = this.dialog.open(SetupPaymentDialogComponent, {
      width: '600px',
      height: 'auto',
      data: { township: this.townshipId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result === 'succeed') {
        this.checkboxCheck = true;
      }
    });
  }

  openForceApiSyncDialog(): void {
    this.dialog.open(ForceApiSyncDialogComponent, {
      width: '375px',
      // data: { type },
    });
  }

  confirmDeleteAddress(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result) {
        this.deleteAddresses = true;
        this.snackBar.open(
          'Druk op opslaan om de adressen definitief te verwijderen',
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        this.deleteAddresses = false;
      }
    });
  }

  openGroupLinkDialog() {
    const dialog = this.dialog.open(GroupLinkDialogComponent, {
      width: '300px',
    });
    dialog.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result.copy) {
        const el = document.createElement('textarea');
        el.value = `${environment.iframeUrl}group-link/${this.townshipId}/${result.groupLink}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.snackBar.open('Link gekopieerd.', 'X', {
          duration: 3000,
        });
      }
    });
  }

  toggleChanges($event: MatSlideToggleChange) {
    this.addressCheckDbl = $event.checked;
    console.log('addressCheckDbl', this.addressCheckDbl);
  }
}
