import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Organisation, User, Voucher, PaymentReference } from '../interfaces';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditOrganisationComponent } from './edit-organisation/edit-organisation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { MarkAsPaidComponent } from './mark-as-paid/mark-as-paid.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { CheckVouchersComponent } from './check-vouchers/check-vouchers.component';
import { InputPaymentreferenceComponent } from './input-paymentreference/input-paymentreference.component';
import { environment } from 'src/environments/environment';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { DiscountComponent } from './dialog/discount/discount.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-organisation-management',
  templateUrl: './organisation-management.component.html',
  styleUrls: ['./organisation-management.component.scss'],
})
export class OrganisationManagementComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  organisationsCollection: AngularFirestoreCollection<Organisation>;
  organisationsDoc: AngularFirestoreDocument<Organisation>;
  organisations: any;
  townShipRef: string;
  organisationId: string;

  paymentReferencesCollection: AngularFirestoreCollection<PaymentReference>;
  paymentReferences: PaymentReference[] = [];
  paymentRef: boolean;

  webshopUrl: string;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        localStorage.setItem('township', userDoc.township);
        this.townShipRef = '/township/' + userDoc.township + '/';
        if (
          !userDoc.organisation &&
          userDoc.rights === 'admin' &&
          this.env.env !== 'township'
        ) {
          this.snackbar.open(
            'Als administrator kan je niet inloggen in de ondernemers omgeving. Je wordt over enkele seconden uitgelogd',
            'X',
            {
              duration: 5000,
            }
          );
          setTimeout(async () => {
            await this.afAuth.signOut();
            this.router.navigate(['/login']);
          }, 4000);
          return;
        }
        if (userDoc.organisation) {
          this.organisationId = userDoc.organisation;
          this.organisationsDoc = this.db.doc<Organisation>(
            '/township/' +
              userDoc.township +
              '/organisations/' +
              userDoc.organisation
          );
          this.organisations = this.organisationsDoc.snapshotChanges().pipe(
            map((element) => {
              const data = [element.payload.data() as any];
              console.log('data', data);
              data[0].id = this.organisationId;
              // this.organisations = data as any;
              this.paymentReferencesCollection = this.db.collection(
                `township/${localStorage.getItem('township')}/organisations/${
                  data[0].id
                }/paymentReferences`,
                (ref) =>
                  ref
                    .where('status', '==', 'done')
                    .orderBy('lastUpdate', 'desc')
              );
              this.paymentReferencesCollection
                .valueChanges()
                .subscribe((references: any) => {
                  references.find((ref) => {
                    if (data[0].paid === false || ref.length <= 0) {
                      return;
                    }
                    if (ref.paid === false) {
                      // this.paymentRef = ref;
                      data[0].paid = ref.paid;
                      data[0].paymentReference = ref;
                    }
                    return ref.paid === false;
                  });
                  // console.log('data', data)
                });
              if (!data[0].claimedVouchers) {
                data[0].claimedVouchers = 0;
              }
              if (!data[0].paidVouchers) {
                data[0].paidVouchers = 0;
              }
              const totalVouchers =
                data[0].claimedVouchers - data[0].paidVouchers;
              if (totalVouchers < 0) {
                data[0].totalVouchers = 0;
              } else {
                data[0].totalVouchers =
                  data[0].claimedVouchers - data[0].paidVouchers;
              }
              return data;
              // console.log('org', this.organisations);
            })
          );
        } else {
          this.organisationsCollection = this.db.collection<Organisation>(
            '/township/' + userDoc.township + '/organisations',
            (ref) => ref.orderBy('name', 'asc')
          );
          this.organisations = this.organisationsCollection
            .snapshotChanges()
            .pipe(
              map((actions) =>
                actions.map((a) => {
                  const data = a.payload.doc.data() as any;
                  console.log('data', data);
                  data.id = a.payload.doc['id'];
                  this.paymentReferencesCollection = this.db.collection(
                    `township/${localStorage.getItem(
                      'township'
                    )}/organisations/${data.id}/paymentReferences`
                  );
                  this.paymentReferencesCollection
                    .valueChanges()
                    .subscribe((references: any) => {
                      references.find((ref) => {
                        if (data.paid === false || ref.length <= 0) {
                          return;
                        }
                        if (ref.paid === false) {
                          // this.paymentRef = ref;
                          data.paid = ref.paid;
                          data.paymentReference = ref;
                        }
                        return ref.paid === false;
                      });
                      // console.log('data', data)
                    });
                  if (!data.claimedVouchers) {
                    data.claimedVouchers = 0;
                  }
                  if (!data.paidVouchers) {
                    data.paidVouchers = 0;
                  }
                  const totalVouchers =
                    data.claimedVouchers - data.paidVouchers;
                  if (totalVouchers < 0) {
                    data.totalVouchers = 0;
                  } else {
                    data.totalVouchers =
                      data.claimedVouchers - data.paidVouchers;
                  }

                  console.log('data', data);
                  return data;
                })
              )
            );
        }
        this.organisations.subscribe((organisations: any) => {
          console.log('organisations', organisations);
          organisations.forEach((organisation) => {
            if (organisation.id === this.organisationId) {
              if (organisation.website) {
                this.webshopUrl = organisation.website;
              }
            }
          });
        });
        console.log('organissations', this.organisations);
      });
    });
  }

  openEditOrganisation(organisation?) {
    this.dialog.open(EditOrganisationComponent, {
      width: '500px',
      data: { organisation },
    });
  }

  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '373px',
      data: { type },
    });
  }
  checkVouchers(organisation?) {
    this.dialog.open(CheckVouchersComponent, {
      width: '500px',
      data: { organisation },
    });
  }
  async recalcStats(organisation) {
    const requestUrl = `${environment.functionsUrl}/httpRecalcStatistics`;
    this.http
      .post(requestUrl, {
        type: 'organisation',
        townshipId: localStorage.getItem('township'),
        organisationId: organisation.id,
      })
      .subscribe((res) => {
        console.log(res);
      });
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  async exportOrganisationVouchers(organisation) {
    // put a dialog in front of here to ask which paymentReference user wants to export, then add that to the filter
    // const inputDialogRef = this.dialog.open(InputPaymentreferenceComponent, {
    //   width: '300px',
    //   data: { organisation },
    // });
    // inputDialogRef.afterClosed().subscribe(async (paymentReference) => {
    //   console.log('The dialog was closed', paymentReference);
    //   if (paymentReference || paymentReference === 0) {
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/',
      (ref) => {
        let fsQuery: any = ref;
        fsQuery = fsQuery.where('claimOrganisationId', '==', organisation.id);
        return fsQuery;
      }
    );
    const vouchersArray = [];
    console.log('voucherRef', voucherRef);
    console.log('voucherRef.ref', voucherRef.ref);
    const vouchersObservable = await voucherRef.get();

    // Make batch array with index to start batches with a bang.
    const batches = [];
    let batchIndex = 0;
    let operationCounter = 0;
    batches[0] = this.db.firestore.batch();

    await vouchersObservable.forEach((vouchers) => {
      // console.log('vouchers', vouchers);
      vouchers.forEach((voucherDoc) => {
        const voucher = voucherDoc.data() as Voucher;
        const exportVoucherObj = {};
        exportVoucherObj['Vouchernummer'] = voucher.number
          ? voucher.number
          : '';
        exportVoucherObj['Waarde'] =
          voucher.value !== null ? Number(voucher.value) : '';
        exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
          ? Number(voucher.amountToPayOrg)
          : '';
        // exportVoucherObj['Image Url'] = voucher.imageUrl
        //   ? voucher.imageUrl
        //   : '';
        exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
        exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
        exportVoucherObj['Huisnummer'] = voucher.houseNumber
          ? voucher.houseNumber
          : '';
        exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
          ? voucher.houseNumberAddition
          : '';
        exportVoucherObj['Activatiedatum'] = voucher.activateDate
          ? voucher.activateDate.toDate().getDate() +
            '-' +
            (voucher.activateDate.toDate().getMonth() + 1) +
            '-' +
            voucher.activateDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
          ? voucher.activateOrganisation
          : '';
        exportVoucherObj['Claimdatum'] = voucher.claimDate
          ? voucher.claimDate.toDate().getDate() +
            '-' +
            (voucher.claimDate.toDate().getMonth() + 1) +
            '-' +
            voucher.claimDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
          ? voucher.claimOrganisation
          : '';
        exportVoucherObj['Betaaldatum'] = voucher.paidDate
          ? voucher.paidDate.toDate().getDate() +
            '-' +
            (voucher.paidDate.toDate().getMonth() + 1) +
            '-' +
            voucher.paidDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Betalingskenmerk'] = voucher.paymentReference
          ? voucher.paymentReference
          : '';
        exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
          ? voucher.validUntilDate.toDate().getDate() +
            '-' +
            (voucher.validUntilDate.toDate().getMonth() + 1) +
            '-' +
            voucher.validUntilDate.toDate().getFullYear()
          : '';
        vouchersArray.push(exportVoucherObj);
        // const ref = this.db
        //   .collection(this.townShipRef + 'vouchers/')
        //   .doc(voucher.number);
        if (!voucher.paidDate) {
          if (operationCounter == 500) {
            batchIndex++;
            operationCounter = 0;
            batches[batchIndex] = this.db.firestore.batch();
          }
          batches[batchIndex].update(voucherDoc.ref, {
            paidDate: new Date(),
          });
          operationCounter++;
        }
      });
    });
    if (vouchersArray.length > 0) {
      console.log('vouchers', vouchersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');
      const date = new Date();
      const dateString =
        date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
      // /* save to file */
      XLSX.writeFile(
        wb,
        'Groene Bon.' + organisation.name + '.' + dateString + '.xlsx'
      );

      // const dialogRef = this.dialog.open(MarkAsPaidComponent, {
      //   width: '300px',
      // });
      // dialogRef.afterClosed().subscribe(async (result) => {
      //   console.log('The dialog was closed', result);
      //   if (result) {
      //     // console.log('paymentRef', paymentReference);
      //     // console.log('org', organisation.id);
      //     batches.forEach(async (batch) => {
      //       console.log({ batch });
      //       await batch.commit();
      //     });
      //     const paymentRef = this.db.doc(
      //       '/township/' +
      //         localStorage.getItem('township') +
      //         `/organisations/${organisation.id}` +
      //         '/paymentReferences/' +
      //         `${paymentReference}`
      //     );

      //     paymentRef.set(
      //       {
      //         paid: true,
      //         lastUpdate: new Date(),
      //       },
      //       { merge: true }
      //     );
      //     this.snackBar.open(
      //       'De bonnen zijn als betaald gemarkeerd.',
      //       'X',
      //       {
      //         duration: 5000,
      //       }
      //     );
      //   }
      // });
    } else {
      // Nothing to export
      this.snackBar.open('Er zijn geen geclaimde bonnen.', 'X', {
        duration: 5000,
      });
    }
    //   }
    // });
  }

  openDiscountDialog(): void {
    const dialogRef = this.dialog.open(DiscountComponent, {
      width: '450px',
    });
  }

  copyIframeUrl(language?) {
    if (!language) {
      language = '';
    } else {
      language = language + '.';
    }
    if (!this.webshopUrl) {
      this.snackBar.open(
        'Er is nog geen webshop url ingesteld. Neem contact op met de beheerder.',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    const el = document.createElement('textarea');
    if (this.env.production) {
      const prefix = this.env.prefix;
      el.value = `https://${language}frame.${prefix}bon.nl/exchange/${localStorage.getItem(
        'township'
      )}/${this.organisationId}`;
    } else {
      el.value = `https://dev.${language}frame.lokalebon.nl/exchange/${localStorage.getItem(
        'township'
      )}/${this.organisationId}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  navigateTo(page: string) {
    console.log('lets navitgate', page);
    this.router.navigate([page, this.organisationId]);
  }
}
