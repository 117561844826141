import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Font, User } from '../interfaces';
import { EditFontComponent } from './edit-font/edit-font.component';

@Component({
  selector: 'app-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss'],
})
export class FontsComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  fontCollection: AngularFirestoreCollection<Font>;
  fonts: Observable<Font[]>;
  townShipRef: string;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        localStorage.setItem('township', userDoc.township);
        this.townShipRef = '/township/' + userDoc.township + '/';
        this.fontCollection = this.db.collection<Font>(
          '/township/' + userDoc.township + '/fonts',
          (ref) => ref.orderBy('name')
        );
        this.fonts = this.fontCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Font;
              data.id = a.payload.doc['id'];

              return { ...data };
            })
          )
        );
        this.fonts.subscribe((fonts) => {
          console.log('fonts', fonts);
        });
      });
    });
  }
  openEditFont(font?) {
    this.dialog.open(EditFontComponent, {
      width: '500px',
      data: { font },
    });
  }
}
