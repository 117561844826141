import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  Organisation,
  OrganisationTag,
  Tag,
  Township,
} from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from '../../../environments/environment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import Hashids from 'hashids';
import { AngularFireStorage } from '@angular/fire/storage';
import * as imageConversion from 'image-conversion';
import { EditSepaComponent } from './edit-sepa/edit-sepa.component';
export interface DialogData {
  organisation: Organisation;
}

@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.scss'],
})
export class EditOrganisationComponent implements OnInit {
  saving: boolean;
  newOrganisation = false;
  organisationId: string;
  organisationForm: FormGroup;
  townShipRef = '/township/' + localStorage.getItem('township') + '/';
  townshipId = localStorage.getItem('township');

  // Everything tags
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activateTagCtrl = new FormControl();
  activateFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  claimTagCtrl = new FormControl();
  claimFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  allTags: Observable<Tag[]>;
  organisationTags: Observable<OrganisationTag[]>;
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  private organisationTagsCollection: AngularFirestoreCollection<OrganisationTag>;
  selectedActivateTags = [];
  selectedClaimTags = [];
  public tagFilterCtrl: FormControl = new FormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);

  apiConnected: boolean;
  hashids: any = new Hashids(
    '',
    18,
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  );

  env = environment;
  isEntrepreneur: boolean;

  // filledInPaymentInfo: any;
  sepaSettings: any;
  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;
  deleteHeaderImg: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditOrganisationComponent>,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage
  ) {}

  async ngOnInit() {
    this.organisationForm = this.fb.group({
      name: [, Validators.required],
      ownerEmail: [, Validators.email],
      apiConnection: [],
      website: [],
      canActivateVouchers: [],
      apiKey: [],
      debtorIban: [],
      debtorBic: [],
      debtorName: [],
      paymentRef: [],
    });
    if (this.data.organisation) {
      this.organisationId = this.data.organisation.id;
      console.log('this.data.organisation', this.data.organisation);
      if (this.data.organisation.externalVouchersOrg) {
        this.organisationForm.addControl('maxVouchers', new FormControl());
      }
      if (this.data.organisation.apiConnection) {
        this.apiConnected = true;
      }
      if (this.data.organisation.sepaSettingsComplete) {
        this.sepaSettings = this.data.organisation.sepaSettings;
      }
      if (
        this.data.organisation.useHeaderImage &&
        this.data.organisation.headerImageUrl
      ) {
        this.headerImgFileAlreadyUploaded = true;
        // this.headerImgFileUploaded
        this.headerImgUrl = this.data.organisation.headerImageUrl;
      }

      this.organisationForm.patchValue(this.data.organisation);
    } else {
      this.organisationId = this.db.createId();
      this.newOrganisation = true;
      this.apiConnected = false;
    }

    this.organisationTagsCollection = this.db.collection<OrganisationTag>(
      this.townShipRef + 'organisations/' + this.organisationId + '/tags'
    );

    // const townshipData = (
    //   await this.db.doc<Township>(this.townShipRef).get().toPromise()
    // ).data();
    // console.log('townshipData', townshipData);
    // if (townshipData.invoiceInfo) {
    //   // set value to field
    //   const invoiceInfo = townshipData.invoiceInfo;
    //   if (invoiceInfo.bankAccount) {
    //     this.organisationForm.controls.debtorIban.setValue(
    //       invoiceInfo.bankAccount
    //     );
    //   }
    //   if (invoiceInfo.name) {
    //     this.organisationForm.controls.debtorName.setValue(invoiceInfo.name);
    //   }
    // }
    if (this.sepaSettings) {
      this.organisationForm.controls.debtorName.setValue(
        this.sepaSettings.name
      );
      this.organisationForm.controls.debtorIban.setValue(
        this.sepaSettings.iban
      );
      this.organisationForm.controls.debtorBic.setValue(this.sepaSettings.bic);
      this.organisationForm.controls.paymentRef.setValue(
        this.sepaSettings.paymentReference
      );
    }
    this.organisationTags = this.organisationTagsCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OrganisationTag;
            data.id = a.payload.doc['id'];
            return data;
          })
        ),
        take(1)
      );
    this.organisationTags.subscribe((value) => {
      value.forEach((tag) => {
        if (tag.activateRights) {
          this.selectedActivateTags.push(tag);
        }
        if (tag.claimRights) {
          this.selectedClaimTags.push(tag);
        }
      });
      console.log('this.selectedActivateTags', this.selectedActivateTags);
      console.log('this.selectedClaimTags', this.selectedClaimTags);
    });
    this.allTagsCollection = this.db.collection<Tag>(`${this.townShipRef}tags`);
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc['id'];
          return data;
        })
      ),
      take(1)
    );
    const combinedActivateFilter = combineLatest(
      this.activateTagCtrl.valueChanges,
      this.allTags
    );
    combinedActivateFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('activateFilteredTags', filteredTags);
        this.activateFilteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.activateTagCtrl.setValue('');

    const combinedClaimFilter = combineLatest([
      this.claimTagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedClaimFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('claimFilteredTags', filteredTags);
        this.claimFilteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.claimTagCtrl.setValue('');

    if (this.env.env === 'township') {
      this.isEntrepreneur = false;
    } else {
      this.isEntrepreneur = true;
    }
  }

  remove(tag: Tag, array: Tag[]): void {
    console.log(tag);
    const index = array.indexOf(tag);

    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  async selected(
    event: MatAutocompleteSelectedEvent,
    array: Tag[],
    input: any
  ): Promise<void> {
    // this.selectedTags.push(event.option.viewValue);
    const value = event.option.value as Tag;
    console.log('value', value);

    const foundTag = await array.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    if (!foundTag) {
      array.push(value);
      this.tagInput.nativeElement.value = '';
      input.setValue('');
    } else {
      this.tagInput.nativeElement.value = '';
      input.setValue('');
      this.snackBar.open('Dit label hangt al aan deze groep.', 'X', {
        duration: 5000,
      });
    }
  }
  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.organisationForm.value } as Organisation;
    saveObj.lowercaseName = saveObj.name.toLowerCase();
    const tagBatch = this.db.firestore.batch();

    // if (!this.urlCheck(saveObj.website)) {
    //   return;
    // }
    if (saveObj.website) {
      if (!this.urlCheck(saveObj.website)) {
        this.organisationForm.controls['website'].setErrors({
          incorrect: true,
        });
        return;
      }
    }

    const firstSepaSetup =
      this.data.organisation &&
      this.data.organisation.sepaSettings &&
      this.data.organisation.sepaSettings.firstSepaSetup
        ? true
        : false;

    const sepaChanged =
      this.data.organisation &&
      this.data.organisation.sepaSettings &&
      (this.organisationForm.value.debtorIban !==
        this.data.organisation.sepaSettings.iban ||
        this.organisationForm.value.debtorName !==
          this.data.organisation.sepaSettings.name ||
        this.organisationForm.value.debtorBic !==
          this.data.organisation.sepaSettings.bic ||
        this.organisationForm.value.paymentRef !==
          this.data.organisation.sepaSettings.paymentReference)
        ? true
        : false;

    if (!firstSepaSetup && sepaChanged) {
      const dialogRef = this.dialog.open(EditSepaComponent, {
        height: 'auto',
        width: '500px',
      });
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult) {
        this.snackBar.open('Uw gegevens zijn aangepast', 'X', {
          duration: 3000,
        });
        saveObj.sepaSettings = {
          iban: this.organisationForm.value.debtorIban
            ? this.organisationForm.value.debtorIban
            : firestore.FieldValue.delete(),
          name: this.organisationForm.value.debtorName
            ? this.organisationForm.value.debtorName
            : firestore.FieldValue.delete(),
          bic: this.organisationForm.value.debtorBic
            ? this.organisationForm.value.debtorBic
            : firestore.FieldValue.delete(),
          paymentReference: this.organisationForm.value.paymentRef
            ? this.organisationForm.value.paymentRef
            : firestore.FieldValue.delete(),
          firstSepaSetup: false,
        };
        console.log('saveObj latest', saveObj);
        if (
          this.organisationForm.value.debtorIban &&
          this.organisationForm.value.debtorName
        ) {
          saveObj.sepaSettingsComplete = true;
        } else {
          saveObj.sepaSettingsComplete = false;
        }
      } else {
        this.saving = false;
        return;
      }
    }
    if (this.data.organisation && firstSepaSetup) {
      saveObj.sepaSettings = {
        iban: this.organisationForm.value.debtorIban
          ? this.organisationForm.value.debtorIban
          : firestore.FieldValue.delete(),
        name: this.organisationForm.value.debtorName
          ? this.organisationForm.value.debtorName
          : firestore.FieldValue.delete(),
        bic: this.organisationForm.value.debtorBic
          ? this.organisationForm.value.debtorBic
          : firestore.FieldValue.delete(),
        paymentReference: this.organisationForm.value.paymentRef
          ? this.organisationForm.value.paymentRef
          : firestore.FieldValue.delete(),
        firstSepaSetup: true,
      };
      if (
        this.organisationForm.value.debtorIban &&
        this.organisationForm.value.debtorName
      ) {
        saveObj.sepaSettingsComplete = true;
        saveObj.sepaSettings.firstSepaSetup = false;
      } else {
        saveObj.sepaSettingsComplete = false;
      }
    } else if (!this.data.organisation) {
      saveObj.sepaSettings = {
        iban: this.organisationForm.value.debtorIban
          ? this.organisationForm.value.debtorIban
          : firestore.FieldValue.delete(),
        name: this.organisationForm.value.debtorName
          ? this.organisationForm.value.debtorName
          : firestore.FieldValue.delete(),
        bic: this.organisationForm.value.debtorBic
          ? this.organisationForm.value.debtorBic
          : firestore.FieldValue.delete(),
        paymentReference: this.organisationForm.value.paymentRef
          ? this.organisationForm.value.paymentRef
          : firestore.FieldValue.delete(),
        firstSepaSetup: true,
      };
      if (
        this.organisationForm.value.debtorIban &&
        this.organisationForm.value.debtorName
      ) {
        saveObj.sepaSettingsComplete = true;
        saveObj.sepaSettings.firstSepaSetup = false;
      } else {
        saveObj.sepaSettingsComplete = false;
      }
    }

    // Check tags for changes
    console.log('selectedActivateTags', this.selectedActivateTags);
    console.log('selectedClaimTags', this.selectedClaimTags);
    const tagsObj = {};
    await this.organisationTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        if (tag.activateRights) {
          delete tag.activateRights;
        }
        if (tag.claimRights) {
          delete tag.claimRights;
        }
        tagsObj[tag.id] = tag;
        tagsObj[tag.id].delete = true;
      });
    });
    if (this.selectedActivateTags) {
      this.selectedActivateTags.forEach((tag) => {
        if (tag.claimRights) {
          delete tag.claimRights;
        }
        if (!tagsObj[tag.id]) {
          tagsObj[tag.id] = tag;
        }
        tagsObj[tag.id].activateRights = true;
        tagsObj[tag.id].delete = false;
      });
    }
    if (this.selectedClaimTags) {
      this.selectedClaimTags.forEach((tag) => {
        if (tag.activateRights) {
          delete tag.activateRights;
        }
        if (!tagsObj[tag.id]) {
          tagsObj[tag.id] = tag;
        }
        tagsObj[tag.id].claimRights = true;
        tagsObj[tag.id].delete = false;
      });
    }
    Object.keys(tagsObj).forEach((tagId) => {
      console.log('tagId', tagId);
      const orgTagRef = this.db.doc(
        `${this.townShipRef}organisations/${this.organisationId}/tags/${tagId}`
      ).ref;
      const tagOrgRef = this.db.doc(
        `${this.townShipRef}tags/${tagId}/organisations/${this.organisationId}`
      ).ref;
      const tagOrgObj = {
        id: this.organisationId,
        name: saveObj.name,
      };
      if (tagsObj[tagId].activateRights) {
        tagOrgObj['activateRights'] = true;
      }
      if (tagsObj[tagId].claimRights) {
        tagOrgObj['claimRights'] = true;
      }
      if (tagsObj[tagId].delete) {
        tagBatch.delete(orgTagRef);
        tagBatch.delete(tagOrgRef);
      } else {
        delete tagsObj[tagId].delete;
        tagBatch.set(orgTagRef, tagsObj[tagId]);
        tagBatch.set(tagOrgRef, tagOrgObj);
      }
      // }
    });

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });

    if (this.headerImgFileUploaded) {
      console.log('headerImgFIleupload', this.headerImgFileUploaded.type);
      try {
        let filePath = `${this.townshipId}/images/${this.organisationId}`;
        let uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      } catch (e: any) {
        console.error(e);
        this.saving = false;
        this.snackBar.open(
          'Er is iets misgegaan. Neem contact op met de beheerder',
          'X',
          {
            duration: 4000,
          }
        );
      }
    }

    if (this.deleteHeaderImg) {
      await this.deleteFile(this.organisationId);
      saveObj.headerImageUrl = null;
      saveObj.useHeaderImage = false;
    }
    console.log('saveObj', saveObj);
    console.log('tagsObj', tagsObj);
    console.log('tagBatch', tagBatch);
    // Save values to tb :)
    await this.db
      .collection(this.townShipRef + 'organisations')
      .doc(this.organisationId)
      .set(saveObj, { merge: true });

    // only township has rights to edit tags
    if (this.env.env === 'township') {
      await tagBatch.commit();
    }
    this.dialogRef.close();
  }

  getError(name) {
    const field = this.organisationForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }

  urlCheck(url) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (url.match(regex)) {
      console.log('valid URL', url);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      this.organisationForm.controls.website.setErrors({
        notMatched: true,
      });
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }

  refreshKey() {
    let id;
    let result = [];
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 18; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    id = result.join('');

    // const apiKey = this.hashids.encodeHex(id)
    // console.log(apiKey);
    this.organisationForm.controls.apiKey.setValue(id);
  }

  setDelete(type: string): void {
    if (type === 'headerImg') {
      this.deleteHeaderImg = true;
    }
    this.snackBar.open(
      `Druk op opslaan om je profiel foto definitief te verwijderen`,
      'X',
      {
        duration: 3500,
      }
    );
  }

  async deleteFile(organisationId: string) {
    const fileUrl = `${this.townshipId}/images/${organisationId}`;
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      // this.snackBar.open(`Pro verwijderd`, 'X', {
      //   duration: 5000,
      // });
      console.log('Avatar deleted');
    });
  }

  async openFile(type: string): Promise<any> {
    if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    }
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  uploadedFile(event, type) {
    const file = event.target.files[0] as File;
    if (type === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    }
  }

  // async convertImageToPng() {
  //   const fileName = this.headerImgFileUploaded.name.substring(
  //     0,
  //     this.headerImgFileUploaded.name.length - 4
  //   );
  //   let reader = new FileReader();
  //   reader.readAsDataURL(this.headerImgFileUploaded);
  //   return new Promise((resolve, reject) => {
  //     reader.onload = () => {
  //       //me.modelvalue = reader.result;
  //       const blob = new Blob([reader.result], { type: 'image/png' });
  //       return resolve(blol);
  //     };
  //     reader.onerror = function (error) {
  //       console.error('Error: ', error);
  //       return reject(error);
  //     };
  //   });
  // }
}
