import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  Organisation,
  PromotionMaterial,
  Township,
  VoucherGroup,
} from "src/app/interfaces";
import { environment } from "../../../../environments/environment";
import { FileSaverService } from "ngx-filesaver";

@Component({
  selector: "app-compile-promo",
  templateUrl: "./compile-promo.component.html",
  styleUrls: ["./compile-promo.component.scss"],
})
export class CompilePromoComponent implements OnInit {
  promoId: string;
  townshipId: string;
  organisationId: string;

  promoData: any;
  promoForm: FormGroup;

  voucherGroups: Observable<VoucherGroup[]>;
  townships: Observable<Township[]>;
  imgUrl: string;

  pdfImgUploaded: File;
  uploadingImg = false;
  loading = false;

  downloadUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private dialogRef: MatDialogRef<CompilePromoComponent>,
    private _FileSaverService: FileSaverService
  ) {}

  async ngOnInit() {
    console.log("dialogData", this.dialogData);
    this.promoId = this.dialogData.promoId;
    this.townshipId = this.dialogData.townshipId;
    this.organisationId = this.dialogData.organisationId;

    this.promoForm = this.fb.group({
      voucherGroupId: [, [Validators.required]],
      couponValue: [""],
      terms: [, [Validators.max(100), Validators.required]],
      township: ["", [Validators.max(20), Validators.required]],
      voucherGroupType: ["", [Validators.required]],
    });
    this.promoData = (await (
      await this.db.doc(`/promotionMaterials/${this.promoId}`).get().toPromise()
    ).data()) as PromotionMaterial;

    this.voucherGroups = this.db
      .collection(`/township/${this.townshipId}/voucherGroups`, (ref) =>
        ref.where("couponValue", ">=", 0)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as VoucherGroup;
            data.id = a.payload.doc["id"];
            return data;
          })
        )
      );

    this.promoForm.controls.voucherGroupId.valueChanges.subscribe(
      async (voucherGroupId) => {
        console.log("voucherGroupId", voucherGroupId);
        const voucherGroup = (
          await this.db
            .doc(`township/${this.townshipId}/voucherGroups/${voucherGroupId}`)
            .get()
            .toPromise()
        ).data() as VoucherGroup;
        console.log("voucherGroup", voucherGroup);

        this.promoForm.controls.couponValue.setValue(voucherGroup.couponValue);
        switch (voucherGroup.type) {
          case "request":
            this.promoForm.controls.voucherGroupType.setValue("Aanvragen");
            break;
          case "claim":
            this.promoForm.controls.voucherGroupType.setValue("Activeren");
            break;
          case "requestActivated":
            this.promoForm.controls.voucherGroupType.setValue("Opvragen");
            break;
          case "sell":
            this.promoForm.controls.voucherGroupType.setValue("Verkopen");
            break;
        }
      }
    );

    const organisation = (
      await this.db
        .doc(`township/${this.townshipId}/organisations/${this.organisationId}`)
        .get()
        .toPromise()
    ).data() as Organisation;
    if (organisation.useHeaderImage) {
      this.imgUrl = organisation.headerImageUrl;
    } else {
      const township = (
        await this.db.doc(`township/${this.townshipId}`).get().toPromise()
      ).data() as Township;
      if (township.useHeaderImage) {
        this.imgUrl = township.headerImageUrl;
      } else {
        this.imgUrl =
          township.voucherPrefix === "groene"
            ? environment.prefixImgGroene
            : environment.prefixImgLokale;
      }
    }
    // this.townships = this.db
    //   .collection(`/township`)
    //   .snapshotChanges()
    //   .pipe(
    //     map((actions) =>
    //       actions.map((a) => {
    //         const data = a.payload.doc.data() as Township;
    //         console.log('data', data);
    //         data.id = a.payload.doc['id'];
    //         return data;
    //       })
    //     )
    //   );
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async uploadedFile(event) {
    this.uploadingImg = true;
    const file = event.target.files[0] as File;
    if (file.size > 2048000) {
      this.snackBar.open("Dit bestand moet onder de 2 MB zijn.", "X", {
        duration: 5000,
      });
      this.uploadingImg = false;
      return;
    } else {
      this.pdfImgUploaded = file;
      try {
        const filePath = `${this.townshipId}/images/${
          this.organisationId
        }-${this.promoData.title.toString().replaceAll(" ", "-")}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.pdfImgUploaded
        );
        this.imgUrl = await uploadTask.ref.getDownloadURL();
        this.uploadingImg = false;
      } catch (e: any) {
        console.error(e);
        this.uploadingImg = false;
        this.snackBar.open(
          "Er is iets misgegaan. Neem contact op met de beheerder",
          "X",
          {
            duration: 4000,
          }
        );
      }
    }
  }

  download() {
    if (this.loading) {
      return;
    }
    const formObj = this.promoForm.value;
    formObj.imgUrl = this.imgUrl;
    formObj.promoId = this.promoId;
    formObj.townshipId = this.townshipId;
    this.loading = true;
    console.log("formObj", formObj);

    const requestUrl = `${environment.functionsUrl}/httpPromoPdf`;

    let res: Observable<any>;
    res = this.http.post(requestUrl, formObj);
    res.subscribe(async (result) => {
      this.loading = false;
      console.log("result", result.downloadUrl);
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        const blob = xhr.response;
        this._FileSaverService.save(blob, "promotie-materiaal.pdf");
        this.dialogRef.close();
      };
      xhr.open("GET", result.downloadUrl);
      xhr.send();
    });
  }
}
