import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PaymentReferences } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { SepaComponent } from '../payment-references/dialog/sepa/sepa.component';

@Component({
  selector: 'app-payment-references',
  templateUrl: './payment-references.component.html',
  styleUrls: ['./payment-references.component.scss'],
})
export class PaymentReferencesComponent implements OnInit {
  env = environment;
  paymentReferenceCollection: AngularFirestoreCollection<PaymentReferences>;
  paymentReferences: Observable<PaymentReferences[]>;

  constructor(public db: AngularFirestore, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.paymentReferenceCollection = this.db.collection(
      `/township/${localStorage.getItem('township')}/paymentReferences`,
      (ref) => ref.where('status', '==', 'done').orderBy('date', 'desc')
    );
    this.paymentReferences = this.paymentReferenceCollection.valueChanges({
      idField: 'id',
    });
    this.paymentReferences.subscribe((paymentReferences) => {
      console.log('paymentReferences', paymentReferences);
    });
  }

  openPaymentReference(paymentReference?) {
    const dialogRef = this.dialog.open(SepaComponent, {
      disableClose: true,
      width: '550px',
      data: {
        paymentReference: paymentReference,
      },
    });
  }
}
