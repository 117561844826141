import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Township } from '../../../interfaces';

@Component({
  selector: 'app-force-api-sync-dialog',
  templateUrl: './force-api-sync-dialog.component.html',
  styleUrls: ['./force-api-sync-dialog.component.scss'],
})
export class ForceApiSyncDialogComponent implements OnInit {
  forceSyncForm: FormGroup;
  townshipRef: AngularFirestoreDocument;

  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForceApiSyncDialogComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.forceSyncForm = this.fb.group({
      forceApiSync: new FormControl('', Validators.requiredTrue),
    });

    this.townshipRef = this.db.doc<Township>(
      'township/' + localStorage.getItem('township')
    );

    const township = this.townshipRef.get();
    township.subscribe((data) => {
      const townshipData = data.data() as Township;
      const forceApiSync = townshipData.forceApiSync;
      this.forceSyncForm.patchValue({
        forceApiSync,
      });
    });
  }

  async save(): Promise<any> {
    await this.townshipRef.set(
      {
        forceApiSync: true,
      },
      { merge: true }
    );

    this.snackBar.open(`Geforceerd vouchers synchronizeren is aangezet.`, 'X', {
      duration: 4000,
    });
    this.dialogRef.close();
  }
}
