import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { SettingsComponent } from './settings/settings.component';
import { TagsComponent } from './tags/tags.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentReferencesComponent } from './payment-references/payment-references.component';
import { PromotionMaterialsComponent } from './promotion-materials/promotion-materials.component';
import { FontsComponent } from './fonts/fonts.component';
import { ExternalVoucherManagementComponent } from './external-voucher-management/external-voucher-management.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UsersComponent } from './users/users.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDefault = () =>
  redirectLoggedInTo([environment.defaultRoute.id]);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDefault },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent,
  },
  dashboardtownship: {
    path: 'dashboard-township',
    component: DashboardTownshipComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  vouchermanagement: {
    path: 'voucher-management',
    component: VoucherManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  externalvouchermanagement: {
    path: 'external-voucher-management',
    component: ExternalVoucherManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  organisationmanagement: {
    path: 'organisation-management',
    component: OrganisationManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  tags: {
    path: 'tags',
    component: TagsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  orders: {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  paymentreferences: {
    path: 'payment-references',
    component: PaymentReferencesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  promotionmaterials: {
    path: 'promotion-materials/:organisationId',
    component: PromotionMaterialsComponent,
  },
  fonts: {
    path: 'fonts',
    component: FontsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  helpdesk: {
    path: 'helpdesk',
    component: HelpdeskComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  users: {
    path: 'users',
    component: UsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
};
console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach((route) => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: environment.defaultRoute.path,
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
    console.log('router3', router);
  }
}
