import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromotionMaterial, Township } from '../interfaces';
import { CompilePromoComponent } from './dialogs/compile-promo/compile-promo.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotion-materials',
  templateUrl: './promotion-materials.component.html',
  styleUrls: ['./promotion-materials.component.scss'],
})
export class PromotionMaterialsComponent implements OnInit {
  promotionMaterials: Observable<PromotionMaterial[]>;
  organisationId: string;
  townshipId = localStorage.getItem('township');

  constructor(
    private router: Router,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      if (params.organisationId) {
        this.organisationId = params.organisationId;
        this.promotionMaterials = await this.db
          .collection(`promotionMaterials`)
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as PromotionMaterial;
                console.log('data', data);
                data.id = a.payload.doc['id'];
                console.log('environment.prefix', environment.prefix);
                if (environment.prefix === 'lokale') {
                  data.imgUrl = data.imgUrlLokale;
                }
                return data;
              })
            )
          );

        this.promotionMaterials.subscribe((promotionMaterial) => {
          console.log('promotionMaterial', promotionMaterial);
        });
      }
    });
  }

  back() {
    this.router.navigate(['organisation-management']);
  }

  openPromoDialog(id: string) {
    this.dialog.open(CompilePromoComponent, {
      width: '575px',
      data: {
        promoId: id,
        organisationId: this.organisationId,
        townshipId: this.townshipId,
      },
      disableClose: true,
    });
  }
}
