import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
// import { CurrencyPipe } from '@angular/common';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  VoucherGroup,
  Voucher,
  Tag,
  Township,
  Address,
} from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { firestore } from 'firebase';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from '../../../environments/environment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { AngularFireStorage } from '@angular/fire/storage';
import { UpgradeDialogComponent } from '../../nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { GenerateVouchersComponent } from '../dialogs/generate-vouchers/generate-vouchers.component';
import Hashids from 'hashids';
import { SelectPdfFontsComponent } from 'src/app/settings/dialog/select-pdf-fonts/select-pdf-fonts.component';
import { HttpClient } from '@angular/common/http';

export interface DialogData {
  township: Township;
  voucherGroup: VoucherGroup;
}

@Component({
  selector: 'app-voucher-group-edit',
  templateUrl: './voucher-group-edit.component.html',
  styleUrls: ['./voucher-group-edit.component.scss'],
})
export class VoucherGroupEditComponent implements OnInit, AfterViewInit {
  env = environment;
  saving: boolean;
  @ViewChild('primary', { static: false }) primary: ElementRef;
  @ViewChild('accent', { static: false }) accent: ElementRef;
  newVoucherGroup = false;
  voucherGroupId: string;
  voucherGroupForm: FormGroup;
  townshipId = localStorage.getItem('township');
  townShipRef = '/township/' + this.townshipId + '/';
  usesExternalVouchers = null;
  // Used for file upload
  storeData: any;
  vouchersFileUploaded: File;
  pdfFileAlreadyUploaded: boolean;
  pdfFileUploaded: File;
  pdfFonts: any;
  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;
  deleteHeaderImg: boolean;
  worksheet: any;
  accentToggle: boolean;
  primaryToggle: boolean;
  maxVouchers: number;
  voucherReminderOptions = [
    { val: 0, text: 'Nooit' },
    { val: 7, text: '7 dagen' },
    { val: 14, text: '14 dagen' },
    { val: 30, text: '30 dagen' },
    { val: 60, text: '60 dagen' },
  ];
  // Everything tags
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  allTags: Observable<Tag[]>;
  voucherGroupTags: Observable<Tag[]>;
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  private voucherGroupTagsCollection: AngularFirestoreCollection<Tag>;
  selectedTags = [];
  filteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  public tagFilterCtrl: FormControl = new FormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);

  shouldGetExternalVouchers: boolean;

  generated: boolean;
  generatedVouchers: any = [];
  hashids: any = new Hashids('', 5, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');
  generatingDone: boolean;

  planId: string;
  error: string;
  deletePdf: boolean;

  paymentSetup = false;
  addressesType: Address[] = [];

  toBeActived: number;
  vouchersToBeGenerated: number;
  voucherPrefix: string;

  isAccesCouponEnv: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VoucherGroupEditComponent>,
    private snackBar: MatSnackBar, // private currencyPipe: CurrencyPipe,
    private http: HttpClient
  ) {}

  rgbToHex(rgb) {
    const rgbArray = rgb.substring(4, rgb.length - 1).split(', ');
    console.log(rgbArray);
    let r = Number(rgbArray[0]).toString(16);
    let g = Number(rgbArray[1]).toString(16);
    let b = Number(rgbArray[2]).toString(16);
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;

    // return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.voucherGroupForm.value.primaryColor) {
        this.voucherGroupForm.controls.primaryColor.setValue(
          this.rgbToHex(getComputedStyle(this.primary.nativeElement).color)
        );
      }
      if (!this.voucherGroupForm.value.accentColor) {
        this.voucherGroupForm.controls.accentColor.setValue(
          this.rgbToHex(getComputedStyle(this.accent.nativeElement).color)
        );
      }
    }, 500);
  }
  async ngOnInit() {
    this.generated = false;
    this.generatingDone = true;
    this.voucherGroupForm = this.fb.group({
      name: [, Validators.required],
      couponValue: [, Validators.required],
      slogan: [, Validators.maxLength(40)],
      type: ['request', Validators.required],
      maxVouchers: [, [Validators.min(1), Validators.pattern('^[0-9]*$')]],
      groupLink: [],
      description: [''],
      terms: ['', Validators.required],
      termsURL: [''],
      voucherReminderTime: [0],
      groupContactEmail: [],
      hideVoucherRemainingInfo: [],
      requestName: [],
      requestPhone: [],
      claimInstantly: [],
      noAddressChecks: [],
      forceSpentFullAmount: [],
      dontSyncWithApi: [],
      dontRequestAddress: [{ value: false, disabled: true }],
      uniqueMailsOnly: [],
      allowUserToSendMail: [],
      validUntilDate: [],
      validUntilTime: [],
      validUntilTimeType: [{ value: 'months', disabled: true }],
      validUntilTimeValue: [{ value: 1, disabled: true }],
      suffix: [''],
      addressType: [[]],
      primaryColor: [
        '',
        [Validators.maxLength(7), Validators.minLength(7), Validators.required],
      ],
      accentColor: [
        '',
        [Validators.maxLength(7), Validators.minLength(7), Validators.required],
      ],
      sellType: [],
      voucherPrice: [],
      administrationCost: [],
      activateFromType: ['activateOnRequest'],
      usableFrom: [],
      // usingAcitvateAfterScan: []
      redirectUrl: [],
      addFieldsToExport: [],
      groupLinkTxt: [],
      // startDate: [],
      // endDate: [],
    });
    console.log('townshipppp', this.data.township);
    if (this.env.name === 'Toegangsbon') {
      this.isAccesCouponEnv = true;
      this.voucherGroupForm.addControl(
        'startDate',
        new FormControl('', Validators.required)
      );
      this.voucherGroupForm.addControl(
        'endDate',
        new FormControl('', Validators.required)
      );
      console.log('controllers are added');
    }
    if (this.data.township.plan && this.data.township.plan.maxVouchers) {
      this.maxVouchers = this.data.township.plan.maxVouchers;
    }
    if (this.data.voucherGroup) {
      console.log('this.data.voucherGroup', this.data.voucherGroup);
      const patchObj = { ...this.data.voucherGroup } as any;
      this.voucherGroupId = this.data.voucherGroup.id;
      if (this.data.voucherGroup.usesExternalVouchers) {
        this.usesExternalVouchers = true;
      } else {
        this.usesExternalVouchers = false;
      }
      if (this.data.voucherGroup.validUntilDate) {
        patchObj.validUntilDate =
          this.data.voucherGroup.validUntilDate.toDate();
      }
      if (patchObj.useCustomPdfTemplate) {
        this.pdfFileAlreadyUploaded = true;
      }
      if (patchObj.useHeaderImage) {
        this.headerImgFileAlreadyUploaded = true;
        this.headerImgUrl = this.data.voucherGroup.headerImageUrl;
      }

      if (patchObj.startDate) {
      }

      if (this.data.voucherGroup.totalVouchers > 0) {
        console.log(
          'disable input forcespentfullamount && dontsyncwithapi since changes wont work properly after vouchers are created'
        );
        this.voucherGroupForm.controls.forceSpentFullAmount.disable();
        this.voucherGroupForm.controls.dontSyncWithApi.disable();
      }
      if (patchObj.startDate) {
        patchObj.startDate = this.data.voucherGroup.startDate.toDate();
      }
      if (patchObj.endDate) {
        patchObj.endDate = this.data.voucherGroup.endDate.toDate();
      }
      this.voucherGroupForm.patchValue(patchObj);
      this.validUntilTimeToggleChanged();
      this.noAddressCheckChanged();
    } else {
      this.voucherGroupId = this.db.createId();
      this.newVoucherGroup = true;
    }
    this.voucherGroupTagsCollection = this.db.collection<Tag>(
      this.townShipRef + 'voucherGroups/' + this.voucherGroupId + '/tags'
    );
    this.voucherGroupTags = this.voucherGroupTagsCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Tag;
            data.id = a.payload.doc['id'];
            return data;
          })
        ),
        take(1)
      );
    this.voucherGroupTags.subscribe((value) => {
      value.forEach((tag) => {
        this.selectedTags.push(tag);
      });
      console.log('this.selectedTags', this.selectedTags);
    });
    this.allTagsCollection = this.db.collection<Tag>(`${this.townShipRef}tags`);
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc['id'];
          return data;
        })
      ),
      take(1)
    );
    const combinedFilter = combineLatest([
      this.tagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('filteredTags', filteredTags);
        this.filteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.tagCtrl.setValue('');

    const townshipData = (
      await this.db
        .doc<Township>(`township/${this.townshipId}`)
        .get()
        .toPromise()
    ).data();
    console.log('township', townshipData);
    if (townshipData.invoiceInfo && townshipData.sisow) {
      this.paymentSetup = true;
      if (this.newVoucherGroup) {
        if (townshipData.primaryColor) {
          this.voucherGroupForm.controls.primaryColor.setValue(
            townshipData.primaryColor
          );
        }
        if (townshipData.accentColor) {
          this.voucherGroupForm.controls.accentColor.setValue(
            townshipData.accentColor
          );
        }
        // headerImgFileUploaded: File;
        if (townshipData.useHeaderImage) {
          this.headerImgUrl = townshipData.headerImageUrl;
          this.headerImgFileAlreadyUploaded = true;
        }
      }
    }

    const addressRef = this.db
      .collection<Township>(`township/${this.townshipId}/addresTypes`)
      .get();
    addressRef.subscribe(async (addresses: any) => {
      addresses.forEach((adres) => {
        const data = adres.data() as any;
        this.addressesType.push(
          data.type.charAt(0).toUpperCase() + data.type.slice(1)
        );
      });
      this.addressesType = [...new Set(this.addressesType)]; // change target to es6 in tsconfig.ts if change compiling goes much slower
    });

    this.voucherGroupForm.controls.maxVouchers.valueChanges.subscribe(
      (result) => {
        if (result > townshipData.plan.maxVouchers) {
          this.voucherGroupForm.controls.maxVouchers.setErrors({
            notMatched: true,
          });
        }
      }
    );
  }

  remove(tag: Tag): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
    // this.selectedTags.push(event.option.viewValue);
    const value = event.option.value as Tag;
    console.log('value', value);

    const foundTag = await this.selectedTags.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    console.log('selectedTags', this.selectedTags);
    console.log('foundTag', foundTag);
    if (!foundTag) {
      this.selectedTags.push(value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
    } else {
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
      this.snackBar.open('Dit label hangt al aan deze groep.', 'X', {
        duration: 5000,
      });
    }
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    console.log('id', id);
    console.log('file.size', file.size);
    if (id === 'vouchers') {
      this.vouchersFileUploaded = file;
      this.readExcel();
    } else if (id === 'pdf') {
      if (file.size > 5120000) {
        this.snackBar.open('Dit bestand moet onder de 5 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      }
      const dialogRef = this.dialog.open(SelectPdfFontsComponent, {
        width: '373px',
        data: { file: file },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('The dialog was closed with result', result);
        if (result) {
          this.pdfFonts = result;
          this.pdfFileUploaded = file;
          console.log('pdf', this.pdfFileUploaded);
        }
      });
    } else if (id === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    }
  }
  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.vouchersFileUploaded);
    console.log('this.vouchersFileUploaded', this.vouchersFileUploaded);
  }
  colorChanged(color, colorController) {
    colorController.setValue(color);
  }
  validUntilTimeToggleChanged() {
    if (this.voucherGroupForm.value.validUntilTime) {
      this.voucherGroupForm.controls.validUntilTimeType.enable();
      this.voucherGroupForm.controls.validUntilTimeValue.enable();
    } else {
      this.voucherGroupForm.controls.validUntilTimeType.disable();
      this.voucherGroupForm.controls.validUntilTimeValue.disable();
    }
  }
  noAddressCheckChanged() {
    if (this.voucherGroupForm.value.noAddressChecks) {
      this.voucherGroupForm.controls.dontRequestAddress.enable();
    } else {
      this.voucherGroupForm.controls.dontRequestAddress.setValue(false);
      this.voucherGroupForm.controls.dontRequestAddress.disable();
    }
  }

  async save() {
    if (this.saving || !this.voucherGroupForm.valid) {
      console.log('this.voucherGroupForm', this.voucherGroupForm);
      this.voucherGroupForm.markAllAsTouched();
      return;
    }
    if (!this.voucherGroupForm.valid) {
      this.snackBar.open('Niet alle verplichte velden zijn ingevuld', 'X', {
        duration: 5000,
      });
      return;
    }
    this.saving = true;

    if (this.generated) {
      this.snackBar.open('Bonnen worden gegenereerd, even geduld a.u.b.', 'X', {
        duration: 3000,
      });
      const generationBatch = [];
      generationBatch.push(this.db.firestore.batch());
      let operationCounter = 0;
      let batchIndex = 0;
      const townsipRef = this.db
        .collection<Voucher>('township' + `/${this.townshipId}/` + 'vouchers')
        .get();
      townsipRef.subscribe(async (townshipData) => {
        let latestNumber = townshipData.docs.length;
        latestNumber += 1;

        console.log('latestNumber', latestNumber);
        const totalNumber = this.vouchersToBeGenerated; // resultDialog.docs.length +
        const suffix = this.voucherGroupForm.value.suffix;
        do {
          const newVoucherName =
            this.voucherPrefix.toUpperCase() +
            this.hashids.encodeHex(latestNumber.toString()) +
            suffix.toUpperCase();
          // console.log('newVoucherName', newVoucherName);
          let newVoucher = {} as Voucher;
          newVoucher = {
            number: newVoucherName, // toUpperCase()
            activateDate: null,
            claimDate: null,
            paidDate: null,
            reminderSend: false,
            voucherGroupId: this.voucherGroupId,
            value: this.voucherGroupForm.value.couponValue,
            distributed: null,
          };
          latestNumber += 1;
          this.generatedVouchers.push(newVoucher);

          const ref = this.db
            .collection(this.townShipRef + 'vouchers/')
            .doc(newVoucher.number);
          generationBatch[batchIndex].set(ref.ref, newVoucher);
          operationCounter++;

          if (operationCounter == 500) {
            generationBatch.push(this.db.firestore.batch());
            batchIndex++;
            operationCounter = 0;
          }
        } while (this.generatedVouchers.length !== totalNumber);
        this.generatingDone = true;
        this.generated = true;
        console.log('generatedVouchers', this.generatedVouchers);
        // console.log('file saved');
        // await generationBatch.commit();
        generationBatch.forEach(async (batch) => {
          await batch.commit();
        });
      });
    }

    if (this.deletePdf) {
      this.deleteFile('pdf');
    }

    if (this.deleteHeaderImg) {
      this.deleteFile('headerImg');
    }
    const tagBatch = this.db.firestore.batch();
    const saveObj = { ...this.voucherGroupForm.value } as VoucherGroup;

    if (saveObj.termsURL) {
      if (!this.urlCheck(saveObj.termsURL, 'terms')) {
        return;
      }
    }
    if (saveObj.redirectUrl) {
      if (!this.urlCheck(saveObj.redirectUrl, 'redirect')) {
        return;
      }
    }
    if (!saveObj.maxVouchers && this.maxVouchers) {
      saveObj.maxVouchers = this.maxVouchers;
    }
    if (!saveObj.noAddressChecks) {
      saveObj.dontRequestAddress = false;
    }

    if (!saveObj.validUntilTime) {
      saveObj.validUntilTimeType = firestore.FieldValue.delete();
      saveObj.validUntilTimeValue = firestore.FieldValue.delete();
    }
    // Check tags for changes
    console.log('selectedTags', this.selectedTags);
    await this.voucherGroupTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        console.log('voucherGroups tag', tag);
        const foundTag = await this.selectedTags.find((lfTag) => {
          if (lfTag.id === tag.id) {
            return lfTag;
          }
        });
        if (!foundTag) {
          tagBatch.delete(
            this.db.doc(
              `${this.townShipRef}voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
            ).ref
          );
        }
      });
    });
    if (this.selectedTags) {
      this.selectedTags.forEach((tag) => {
        tagBatch.set(
          this.db.doc(
            `${this.townShipRef}voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
          ).ref,
          tag
        );
      });
    }

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });

    if (saveObj.voucherReminderTime === 0) {
      saveObj.voucherReminderTime = firestore.FieldValue.delete();
    }
    // console.log('worksheet', this.worksheet);

    let spreadsheetError;
    const batches = [];
    batches[0] = this.db.firestore.batch();
    let batchIndex = 0;
    let operationCounter = 0;
    let totalVouchers = 0;
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            // console.log('key', key);
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] = this.worksheet[key].w;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'vouchernummer':
            columnNames[key] = 'number';
            break;
          case 'waarde':
            columnNames[key] = 'value';
            break;
          case 'e-mail':
            columnNames[key] = 'email';
            break;
          case 'te betalen waarde':
            columnNames[key] = 'amountToPayOrg';
            break;
          case 'postcode':
            columnNames[key] = 'postal';
            break;
          // case 'image url':
          //   columnNames[key] = 'imageUrl';
          //   break;
          case 'huisnummer':
            columnNames[key] = 'houseNumber';
            break;
          case 'toevoeging':
            columnNames[key] = 'houseNumberAddition';
            break;
          case 'kenmerk':
            columnNames[key] = 'type';
            break;
          case 'type':
            columnNames[key] = 'type';
            break;
          case 'uitgegeven':
            columnNames[key] = 'distributed';
            break;
          case 'claimbedrijf':
            columnNames[key] = 'claimOrganisation';
            break;
          case 'claimdatum':
            columnNames[key] = 'claimDate';
            break;
          case 'activatiebedrijf':
            columnNames[key] = 'activateOrganisation';
            break;
          case 'activatiedatum':
            columnNames[key] = 'activateDate';
            break;
          case 'betaaldatum':
            columnNames[key] = 'paidDate';
            break;
          case 'vervaldatum':
            columnNames[key] = 'validUntilDate';
            break;
          case 'herinnering verstuurd':
            columnNames[key] = 'reminderSend';
            break;
          // case 'wie pakt op':
          //   columnNames[key] = 'responsible';
          //   break;
          // case 'contact gehad op':
          //   columnNames[key] = 'contactedOn';
          //   break;
          // case 'afspraak gepland op':
          //   columnNames[key] = 'appointmentDate';
          //   break;
          // case 'vversterkte materialen':
          //   columnNames[key] = 'usedMaterials';
          //   break;
          // case 'evt opmerkingen':
          //   columnNames[key] = 'notes';
          //   break;
        }
      });
      delete spreadsheet[1];
      // console.log('columnNames', columnNames);
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {} as Voucher;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log('colName', colName);
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // now check if current field needs extra processing, overriding rowObj2
            if (colName === 'value' || colName === 'amountToPayOrg') {
              rowObj[colName] = Number(spreadsheet[key][colKey]);
            }
            if (
              colName === 'activateDate' ||
              colName === 'claimDate' ||
              colName === 'paidDate' ||
              colName === 'validUntilDate'
              // colName === 'appointmentDate' ||
              // colName === 'contactedOn'
            ) {
              const dateArray = spreadsheet[key][colKey].split('-');
              const dateString =
                dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
              const timestamp = Date.parse(dateString);
              // if (date == '')
              let date = null;
              if (isNaN(timestamp) === false) {
                date = new Date(timestamp);
                date = new firestore.Timestamp(
                  Math.floor(date.getTime() / 1000),
                  0
                );
              } else {
                console.log('date failure on this voucher:', rowObj);
                spreadsheetError = true;
              }
              rowObj[colName] = date;
            }
            if (colName === 'claimOrganisation') {
              rowObj[colName] = spreadsheet[key][colKey].toLowerCase();
            }
            if (colName === 'type') {
              rowObj[colName] = spreadsheet[key][colKey].split();
            }

            // set rowObj with value from spreadsheet for ALL fields
          }
        });

        // These line puts undefined dates to null so that it can be queried on with where
        if (!rowObj.activateDate) {
          rowObj.activateDate = null;
        }
        if (!rowObj.claimDate) {
          rowObj.claimDate = null;
        }
        if (!rowObj.paidDate) {
          rowObj.paidDate = null;
        }
        if (!rowObj.distributed) {
          rowObj.distributed = null;
          rowObj.activateFromType = false;
        } else {
          rowObj.distributed = true;
        }
        if (!rowObj.reminderSend) {
          rowObj.reminderSend = false;
        } else {
          rowObj.reminderSend = true;
        }

        rowObj.voucherGroupId = this.voucherGroupId;
        // console.log('rowObj', rowObj);

        // Only upload voucher if it has both of the required values
        if (!isNaN(rowObj.value) && rowObj.number) {
          totalVouchers++;
          const voucherRef = this.db
            .collection(this.townShipRef + 'vouchers/')
            .doc(rowObj['number']);

          if (operationCounter == 500) {
            batchIndex++;
            operationCounter = 0;
            batches[batchIndex] = this.db.firestore.batch();
          }

          batches[batchIndex].set(voucherRef.ref, rowObj, { merge: true });
          operationCounter++;
        } else if (rowObj.number) {
          spreadsheetError = true;
        }
      });
      // console.log('batches', batches);
      // Object.keys(batches).forEach((key) => {
      //   const currentRow = spreadsheetObj[key];
      // });
    }

    if (!spreadsheetError) {
      // Upload files
      if (this.pdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
        await this.storage.upload(filePath, this.pdfFileUploaded);
        saveObj.useCustomPdfTemplate = true;
        saveObj.pdfFonts = this.pdfFonts;
      }
      if (this.headerImgFileUploaded) {
        const filePath = `${this.townshipId}/images/${this.voucherGroupId}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }

      if (this.deletePdf) {
        saveObj.useCustomPdfTemplate = false;
      }

      if (this.deleteHeaderImg) {
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }

      if (saveObj.activateFromType !== 'activateOnDate') {
        saveObj.usableFrom = null;
      }

      if (this.shouldGetExternalVouchers) {
        saveObj.usesExternalVouchers = true;
        saveObj.externalVoucherGroupId =
          this.data.township.externalVoucherGroupId;
      }

      console.log('saveObj', saveObj);
      await this.db
        .collection(this.townShipRef + 'voucherGroups')
        .doc(this.voucherGroupId)
        .set(saveObj, { merge: true });
      await tagBatch.commit();
      batches.forEach(async (batch) => {
        // console.log('batch', batch);
        await batch.commit();
      });
      if (totalVouchers > this.maxVouchers - 1) {
        this.dialog.open(UpgradeDialogComponent, {
          width: '500px',
        });
      }
      if (this.shouldGetExternalVouchers) {
        const requestUrl = `${environment.functionsUrl}/httpGetExternalVouchers`;
        this.http
          .post(requestUrl, {
            townshipId: this.townshipId,
            voucherGroupId: this.voucherGroupId,
            externalVoucherGroupId: this.data.township.externalVoucherGroupId,
            amount: this.vouchersToBeGenerated,
            value: this.voucherGroupForm.value.couponValue,
          })
          .subscribe((response) => {
            console.log('res', response);
            this.dialogRef.close();
          });
      } else {
        this.dialogRef.close();
      }
    } else {
      this.snackBar.open(
        'Eén of meer vouchers bevatten niet alle vereisde velden: waarde of vouchernummer.',
        'X',
        {
          duration: 3000,
        }
      );
      this.saving = false;
    }
    this.generated = false;
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  async delete() {
    console.log('voucherGroupForm', this.voucherGroupForm.value);
  }

  generateVouchers() {
    const ref = this.db.doc<Township>(`township/${this.townshipId}`).get();
    ref.subscribe((result) => {
      const data = result.data();
      console.log('data', data);
      if (typeof data.plan !== 'undefined' && data.plan.id) {
        // !data.plan doesnt include undefined
        this.planId = data.plan.id;
      } else {
        this.planId = 'free';
      }
      this.voucherPrefix = data.prefix;
      this.openGenerateVouchersDialog();
    });
  }

  useExternalVouchers() {
    const dialogRef = this.dialog.open(GenerateVouchersComponent, {
      width: '350px',
      data: { voucherGroupId: this.voucherGroupId, type: 'external' },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (this.data.township.plan.id === 'free' && result > 10) {
        this.dialog.open(UpgradeDialogComponent, {
          width: '500px',
        });
        return;
      }
      console.log('The dialog was closed', result);
      if (typeof result === 'undefined') {
        this.shouldGetExternalVouchers = false;
        return;
      }
      this.shouldGetExternalVouchers = true;
      this.vouchersToBeGenerated = result;
      this.usesExternalVouchers = true;
      // this.generatingDone = false;
    });
    // const ref = this.db.doc<Township>(`township/${this.townshipId}`).get();
    // ref.subscribe((result) => {
    //   const data = result.data();
    //   console.log('data', data);
    //   if (typeof data.plan !== 'undefined' && data.plan.id) {
    //     // !data.plan doesnt include undefined
    //     this.planId = data.plan.id;
    //   } else {
    //     this.planId = 'free';
    //   }
    //   this.voucherPrefix = data.prefix;
    //   this.openGenerateVouchersDialog();
    // });
  }

  openGenerateVouchersDialog(): void {
    this.generatedVouchers = [];
    const dialogRef = this.dialog.open(GenerateVouchersComponent, {
      width: '350px',
      data: { voucherGroupId: this.voucherGroupId, type: 'local' },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (this.planId === 'free' && result > 10) {
        this.dialog.open(UpgradeDialogComponent, {
          width: '500px',
        });
        return;
      }
      console.log('The dialog was closed', result);
      if (typeof result === 'undefined') {
        this.generated = false;
        this.generatingDone = true;
        return;
      }
      this.generated = true;
      this.vouchersToBeGenerated = result;
      this.usesExternalVouchers = false;
      // this.generatingDone = false;
    });
  }
  urlCheck(saveObj: string, controller: string) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      if (controller === 'terms') {
        this.voucherGroupForm.controls.termsURL.setErrors({
          notMatched: true,
        });
      } else if (controller === 'redirect') {
        this.voucherGroupForm.controls.redirectUrl.setErrors({
          notMatched: true,
        });
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
  // dialogRef.afterOpened().subscribe(result => {
  // })

  async openFile(type: string): Promise<any> {
    if (type === 'pdf') {
      let fileUrl = '';
      if (this.pdfFileUploaded || this.pdfFileAlreadyUploaded) {
        fileUrl = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
      } else {
        fileUrl = 'pdf-templates/groene-bon.pdf';
      }
      const file = this.storage.ref(fileUrl).getDownloadURL();
      file.subscribe((url) => {
        console.log('file', url);
        window.open(url);
      });
    } else if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    }
    console.log('vouchergroupid', this.voucherGroupId);
  }

  deleteFile(type: string): void {
    let segment;
    let extension = '';
    if (type === 'pdf') {
      segment = 'pdf-templates';
      extension = '.pdf';
    } else if (type === 'headerImg') {
      segment = 'images';
    }
    const name = type === 'pdf' ? 'PDF' : 'E-mail header';
    const fileUrl = `${this.townshipId}/${segment}/${this.voucherGroupId}${extension}`;
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      this.snackBar.open(`${name} verwijderd`, 'X', {
        duration: 5000,
      });
    });
  }

  setDelete(type: string): void {
    const name = type === 'pdf' ? 'PDF' : 'E-mail header';
    if (type === 'pdf') {
      this.deletePdf = true;
    } else if (type === 'headerImg') {
      this.deleteHeaderImg = true;
    }
    this.snackBar.open(
      `Druk op opslaan om de ${name} definitief te verwijderen`,
      'X',
      {
        duration: 3500,
      }
    );
  }
  // transformInputAmount(element) {
  //   let currentVal = element.target.value;
  //   element.target.value = Number(this.transformAmount(currentVal))
  // }
  // transformAmount(value) {
  //   let pipeVal = value.replace("€", "");
  //   pipeVal = pipeVal.replaceAll(".", "");
  //   pipeVal = pipeVal.replace(",", ".");
  //   console.log('currPipe', this.currencyPipe.transform(pipeVal, 'EUR', '', null, 'en-US'));
  //   return this.currencyPipe.transform(pipeVal, 'EUR', '', null, 'en-US');
  // }
}
