import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Township } from '../../../interfaces';
import { environment } from '../../../../environments/environment';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { isThisSecond } from 'date-fns';
import { MatStepper } from '@angular/material/stepper';
import { firestore } from 'firebase';
@Component({
  selector: 'app-setup-payment-dialog',
  templateUrl: './setup-payment-dialog.component.html',
  styleUrls: ['./setup-payment-dialog.component.scss'],
})
export class SetupPaymentDialogComponent implements OnInit {
  env = environment;
  personalForm: FormGroup;
  paymentForm: FormGroup;
  mollieFormGroup: FormGroup;

  townshipDoc: AngularFirestoreDocument<Township>;
  townshipId = localStorage.getItem('township');

  waiting = false;
  @ViewChild('stepper')
  private stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    // @Inject(MAT_DIALOG_DATA) public townshipId: Township,
    private snackBar: MatSnackBar,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<SetupPaymentDialogComponent>
  ) {}

  ngOnInit() {
    this.personalForm = this.formBuilder.group({
      street: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      houseNumberAddition: ['', []],
      postal: [
        '',
        [Validators.required, Validators.maxLength(7), Validators.minLength(6)],
      ],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });

    this.paymentForm = this.formBuilder.group({
      kvk: ['', [Validators.required]],
      vat: ['', [Validators.required]],
      bankAccount: ['', [Validators.required]],
      bic: [''],
      debtorId: [''],
      invoicesSent: ['', []],
    });

    this.mollieFormGroup = this.formBuilder.group({
      merchantKey: [],
      merchantId: [],
      shopId: [],
      mollieProfileId: [],
      paymentSystem: [],
    });

    this.townshipDoc = this.db.doc<Township>('township/' + this.townshipId);
    const township = this.townshipDoc.get();
    township.subscribe((data) => {
      const townshipData = data.data() as Township;
      console.log('townshipData', townshipData.mollieProfileId);
      if (townshipData.invoiceInfo) {
        this.personalForm.patchValue(townshipData.invoiceInfo);
        this.paymentForm.patchValue(townshipData.invoiceInfo);

        if (townshipData.sisow) {
          if (townshipData.sisow.merchantKey && townshipData.sisow.merchantId) {
            this.mollieFormGroup.patchValue({
              merchantKey: townshipData.sisow.merchantKey,
              merchantId: townshipData.sisow.merchantId,
            });

            if (townshipData.sisow.shopId) {
              this.mollieFormGroup.patchValue({
                shopId: townshipData.sisow.shopId,
              });
            }
          }
        }

        if (townshipData.mollieProfileId) {
          this.mollieFormGroup.patchValue({
            mollieProfileId: townshipData.mollieProfileId,
          });
        }

        if (townshipData.paymentSystem) {
          this.mollieFormGroup.patchValue({
            paymentSystem: townshipData.paymentSystem,
          });
        }
        // this.invoiceForm.patchValue(townshipData.invoiceInfo);
      }
    });
  }

  nextStep() {
    const vatNumber = this.paymentForm.value.vat.toLowerCase();
    console.log('vatNumber', vatNumber);
    if (!vatNumber.includes('nl') && !vatNumber.includes('b')) {
      this.paymentForm.controls.vat.setErrors({
        notMatched: true,
      });
      console.log('not good');
      return;
    }
    this.stepper.next();
  }

  connectToMollie(): void {
    window.open(
      `${this.env.functionsUrl}/mollieAuthRequest?townshipId=${this.townshipId}`
    );
  }

  getError(name, form: FormGroup) {
    const field = form.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      if (field.hasError('email')) {
        return 'Je moet een geldig e-mailadres invullen.';
      }
      if (field.hasError('pattern')) {
        return 'Je moet een geldig postcode invullen.';
      }
      if (field.hasError('minlength')) {
        return 'Je moet een geldig telefoonnummer invullen.';
      }
      return '';
    }
  }

  async save(): Promise<any> {
    this.waiting = true;
    const personalData = this.personalForm.value;
    const paymentData = this.paymentForm.value;
    const invoiceInfo = { ...personalData, ...paymentData };

    let sepaSettingsComplete = false;
    if (this.paymentForm.value.bankAccount && this.paymentForm.value.debtorId) {
      sepaSettingsComplete = true;
    }

    invoiceInfo.bic = this.paymentForm.value.bic
      ? this.paymentForm.value.bic
      : firestore.FieldValue.delete();

    const paymentSystem = this.mollieFormGroup.value.paymentSystem;
    if (paymentSystem === 'mollie') {
      const mollieProfileId = this.mollieFormGroup.value.mollieProfileId;
      await this.db.doc(`/township/${this.townshipId}/`).set(
        {
          invoiceInfo,
          paymentSystem,
          sepaSettingsComplete,
          mollieProfileId: mollieProfileId,
        },
        { merge: true }
      );
    } else {
      const merchantKey = this.mollieFormGroup.value.merchantKey;
      const merchantId = this.mollieFormGroup.value.merchantId;
      const shopId = this.mollieFormGroup.value.shopId
        ? this.mollieFormGroup.value.shopId
        : '';
      await this.db.doc(`/township/${this.townshipId}/`).set(
        {
          invoiceInfo,
          paymentSystem,
          sepaSettingsComplete,
          sisow: {
            merchantKey,
            merchantId,
            shopId,
          },
        },
        { merge: true }
      );
    }
    this.waiting = false;
    this.snackBar.open('Betaalinstellingen zijn ingesteld', 'X', {
      duration: 5000,
    });
    this.dialogRef.close('succeed');
  }
}
