import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Voucher, Organisation } from 'src/app/interfaces';
import { firestore } from 'firebase';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface DialogData {
  voucherNumber: string;
}

@Component({
  selector: 'app-check-voucher',
  templateUrl: './check-voucher.component.html',
  styleUrls: ['./check-voucher.component.scss'],
})
export class CheckVoucherComponent implements OnInit {
  numberForm: FormGroup;
  voucherForm: FormGroup;
  voucherNumber: string;
  voucher: Voucher;
  voucherDoc: AngularFirestoreDocument<Voucher>;
  saving: boolean;

  voucherValue: string;
  amountToPay: string;

  organisationCollection: AngularFirestoreCollection<Organisation>;
  organisations: Observable<Organisation[]>;
  organisationsArray: Array<Organisation>;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CheckVoucherComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.voucherForm = this.fb.group({
      receiptValue: [, Validators.required],
    });
    this.voucherForm
      .get('receiptValue')
      .valueChanges.subscribe((receiptValue) => {
        console.log('receiptValue', receiptValue);
        this.amountToPay = (receiptValue - this.voucher.value > 0
          ? receiptValue - this.voucher.value
          : 0
        )
          .toFixed(2)
          .replace('.', ',');
        console.log('amountToPay', this.amountToPay);
      });
    if (this.data.voucherNumber) {
      this.getVoucher(this.data.voucherNumber);
    }

    this.organisationCollection = this.db.collection<Organisation>(
      'township/' + localStorage.getItem('township') + '/organisations/',
      (ref) => ref.orderBy('name', 'asc')
    );
    this.organisations = this.organisationCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Organisation;
          const id = a.payload.doc['id'];
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.organisations.subscribe((organisations) => {
      // console.log('products', products);
      this.organisationsArray = organisations;
    });
  }

  async getVoucher(voucherNumber) {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.voucherDoc = this.db.doc<Voucher>(
      'township/' +
        localStorage.getItem('township') +
        '/vouchers/' +
        voucherNumber
    );
    this.voucher = (await this.voucherDoc.ref.get()).data() as Voucher;
    if (this.voucher) {
      this.voucherValue = this.voucher.value.toFixed(2).replace('.', ',');
      console.log('this.voucher', this.voucher);
      const patchObj = this.voucher as any;
      if (this.voucher.activateDate) {
        patchObj.activateDate = this.voucher.activateDate.toDate();
      }
      if (this.voucher.claimDate) {
        patchObj.claimDate = this.voucher.claimDate.toDate();
      }
      if (this.voucher.paidDate) {
        patchObj.paidDate = this.voucher.paidDate.toDate();
      }
      this.voucherNumber = this.voucher.number;
      this.voucherForm.patchValue(this.voucher);
    }
    this.saving = false;
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.voucherForm.value };
    saveObj.amountToPayOrg =
      saveObj.receiptValue > this.voucher.value
        ? this.voucher.value
        : saveObj.receiptValue;
    // Make convert empty strings to 'null' for our queries.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = null;
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = null;
      }
    });
    console.log('saveObj', saveObj);
    // Save values to tb :)
    await this.voucherDoc.set(saveObj, { merge: true });
    this.dialogRef.close();
  }
}
