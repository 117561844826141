import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { VoucherGroup } from 'src/app/interfaces';

@Component({
  selector: 'app-group-link-dialog',
  templateUrl: './group-link-dialog.component.html',
  styleUrls: ['./group-link-dialog.component.scss'],
})
export class GroupLinkDialogComponent implements OnInit {
  groupLinkForm: FormGroup;
  groupLinks: string[];

  townshipId = localStorage.getItem('township');

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private dialogRef: MatDialogRef<GroupLinkDialogComponent>
  ) {}

  async ngOnInit(): Promise<any> {
    this.groupLinkForm = this.fb.group({
      groupLink: [, Validators.required],
    });

    const allGroupLinks = (
      await this.db
        .collection(`township/${this.townshipId}/voucherGroups`, (ref) =>
          ref.where('groupLink', '!=', '')
        )
        .get()
        .toPromise()
    ).docs;
    console.log('allGroupLinks', allGroupLinks);
    let groupLinksArr = [];
    allGroupLinks.forEach((groupLink) => {
      groupLinksArr.push((groupLink.data() as VoucherGroup).groupLink);
    });
    this.groupLinks = [...new Set(groupLinksArr)]; // remove all duplicates

    // allGroupLinks.subscribe((groupLink) => {
    //   console.log('groupLink', groupLink.);
    // });
  }

  copy() {
    if (this.groupLinkForm.valid) {
      this.dialogRef.close({
        copy: true,
        groupLink: this.groupLinkForm.value.groupLink,
      });
    }
  }
}
