import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Discount,
  User,
  VoucherGroup,
  Organisation,
} from '../../../interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent implements OnInit {
  env = environment;
  discountForm: FormGroup;

  action: string;

  dicountFileUploaded: File;
  pdfFileAlreadyUploaded: boolean;
  pdfFileUploaded: File;
  storeData: any;
  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  worksheet: any;

  bonTxt: string;

  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;

  orgId: string;
  ref: AngularFirestoreCollection<Discount>;

  discounts: any[] = [];
  voucherGroups: Observable<any>;
  explanation: boolean;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.explanation = false;
    this.discountForm = this.fb.group({
      voucherGroup: [, Validators.required],
      explanation: [, Validators.required],
    });
    this.afAuth.user.forEach((user) => {
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        this.orgId = userDoc.organisation;
        this.ref = this.db.collection(
          `township/${localStorage.getItem('township')}/organisations/${
            this.orgId
          }/discountCode`
        );
        this.ref.get().forEach((discounts) => {
          if (!discounts.empty) {
            discounts.forEach((discount) => {
              const data = discount.data();
              console.log('data', data);
              this.discounts.push(data);
            });
          }
        });
      });
      this.getVoucherGroup();
    });

    this.discountForm.controls.explanation.valueChanges.subscribe((result) => {
      this.action = 'action';
    });
  }

  async getVoucherGroup(): Promise<any> {
    const voucherGroupRef = this.db.collection(
      `township/${localStorage.getItem('township')}/voucherGroups`
    );
    this.voucherGroups = voucherGroupRef.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as VoucherGroup;
          data.id = a.payload.doc['id'];
          return data;
        })
      )
    );
  }

  detectChange(): void {
    this.discountForm.controls.explanation.setValue('');
    this.getVoucherGroup();

    console.log('data', this.discountForm.value.voucherGroup);
    const explanationRef = this.db.doc(
      `township/${localStorage.getItem('township')}/organisations/${
        this.orgId
      }/discountCode/${this.discountForm.value.voucherGroup}`
    );
    explanationRef.get().forEach((explanations) => {
      if (!explanations.exists) {
        return;
      }
      const data = explanations.data() as any;
      console.log('data', data);
      if (data.explanation) {
        // explanation = data.explanation;
        this.discountForm.controls.explanation.setValue(data.explanation);
      }
    });
    this.explanation = true;
  }

  openFileInput(id) {
    this.action = 'importeren';
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  uploadedFile(event) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    console.log('file.size', file.size);
    this.dicountFileUploaded = file;
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.dicountFileUploaded);
    console.log('this.dicountFileUploaded', this.dicountFileUploaded);
    this.bonTxt = this.dicountFileUploaded.name;
  }

  async save(): Promise<any> {
    const formData = this.discountForm.value;
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        console.log('key', key);
        try {
          if (
            key !== '!ref' &&
            key !== '!margins' &&
            key !== '!autofilter' &&
            key !== '!merges'
          ) {
            // console.log('key', key);
            const rowId = key.match(/\d+/g).toString();
            const colId = key.match(/[a-zA-Z]+/g).toString();
            if (!spreadsheet[rowId]) {
              spreadsheet[rowId] = {};
            }
            spreadsheet[rowId][colId] = this.worksheet[key].w;
          }
        } catch (error) {
          console.log('key with error:', key);
          console.error(error);
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      console.log('columnNames', columnNames);
      let availablePresent = false;
      if (
        !Object.values(columnNames).toString().toLowerCase().includes('code')
      ) {
        this.snackBar.open(
          'Het excel bestand mist het verplichte veld: "Code".',
          'X',
          {
            duration: 5000,
          }
        );
        return;
      }
      if (
        !Object.values(columnNames).toString().toLowerCase().includes('waarde')
      ) {
        this.snackBar.open(
          'Het excel bestand mist het verplichte veld: "Waarde".',
          'X',
          {
            duration: 5000,
          }
        );
        return;
      }
      if (Object.values(columnNames).includes('aantal')) {
        availablePresent = true;
        return;
      }
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        console.log('val', val);
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'code':
            columnNames[key] = 'code';
            break;
          case 'waarde':
            columnNames[key] = 'value';
            break;
          case 'aantal beschikbaar':
            columnNames[key] = 'available';
            break;
          case 'ingewisseld':
            columnNames[key] = 'redeemed';
            break;
        }
      });
      delete spreadsheet[1];
      console.log('columnNames', columnNames);

      if (Object.keys(columnNames).length < 2) {
        this.snackBar.open('Het bestand bevat ongeldige velden.', 'X', {
          duration: 5000,
        });
        return;
      }
      // batches[0] = this.db.firestore.batch();
      let double = false;
      let discountDouble = null;
      const batch = this.db.firestore.batch();
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {} as Discount;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          console.log('colName', colName);
          this.discounts.forEach((discountId) => {
            console.log('discountId.code', discountId.code);
            console.log('spreadsheet[key][colKey]', spreadsheet[key][colKey]);
            if (
              colName === 'code' &&
              discountId.code === spreadsheet[key][colKey]
            ) {
              // formData.voucherGroup == discountId.voucherGroup
              // if (discountId.voucherGroup === formData.voucherGroup) {
              double = true;
              discountDouble = spreadsheet[key][colKey];
              return;
              // }
            }
          });
          if (rowObj[colName] === 'redeemed') {
            rowObj[colName] = spreadsheet[key][colKey].toLowerCase();
            return;
          }
          if (colName === 'available') {
            rowObj['original'] = Number(spreadsheet[key][colKey]);
            rowObj[colName] = Number(spreadsheet[key][colKey]);
            return;
          }
          if (colName === 'value') {
            rowObj[colName] = Number(spreadsheet[key][colKey]);
            return;
          }
          if (!availablePresent) {
            rowObj['available'] = 1;
            rowObj['original'] = 1;
          }
          rowObj[colName] = spreadsheet[key][colKey].toLowerCase();
          rowObj['voucherGroup'] = formData.voucherGroup;
          rowObj['previousExchanges'] = [];
        });
        const docRef = this.ref.doc(rowObj['code']);
        console.log('ref', docRef.ref);
        batch.set(docRef.ref, rowObj);
        console.log('spreadsheet', rowObj);
      });
      if (double) {
        this.snackBar.open(
          `Kortingscode: ${discountDouble} is al geïmporteerd. Kortingscodes mogen 1 keer geïmporteerd worden`,
          'X',
          {
            duration: 5000,
          }
        );
        return;
      }
      console.log('batch', batch);
      await batch.commit();
    }
    console.log('formData', formData);
    if (formData.explanation || formData.explanation === '') {
      console.log('formData.explanation', formData.explanation);
      const orgRef = this.db.doc(
        `township/${localStorage.getItem('township')}/organisations/${
          this.orgId
        }/discountCode/${formData.voucherGroup}`
      );
      orgRef.set(
        {
          explanation: formData.explanation,
          voucherGroup: formData.voucherGroup,
        },
        { merge: true }
      );
    }
    if (this.worksheet) {
      this.snackBar.open('Kortingscodes geïmporteerd.', 'X', {
        duration: 6500,
      });
    } else {
      this.snackBar.open('Actie voltooid.', 'X', {
        duration: 6500,
      });
    }
  }

  async export(): Promise<any> {
    const exportArray = [];
    let notFound = false;
    const discountObservable = await this.db
      .collection(
        `township/${localStorage.getItem('township')}/organisations/${
          this.orgId
        }/discountCode`,
        (ref) =>
          ref.where('voucherGroup', '==', this.discountForm.value.voucherGroup)
      )
      .get();
    await discountObservable.forEach((discounts) => {
      console.log('discounts', discounts);
      discounts.forEach((discountDoc) => {
        let exportObj = {};
        const discount = discountDoc.data() as Discount;
        console.log('discount', discount);
        if (!discount.previousExchanges) {
          notFound = true;
          return;
        }
        if (discount.previousExchanges.length === 0) {
          console.log('no previous exchanges');
          exportObj['code'] = discount.code;
          exportObj['waarde'] = discount.value;
          exportObj['aantal beschikbaar'] = discount.available;
          exportArray.push(exportObj);
        }

        discount.previousExchanges.forEach((element: any, index) => {
          notFound = false;
          exportObj = {};
          console.log('element', element);
          exportObj['code'] = discount.code;
          exportObj['waarde'] = discount.value;
          exportObj['aantal beschikbaar'] = discount.original - index;
          exportObj['ingewisseld'] = element.redeemed
            ? (exportObj['ingewisseld'] =
                element.redeemed.toDate().getDate() +
                '-' +
                (element.redeemed.toDate().getMonth() + 1) +
                '-' +
                element.redeemed.toDate().getFullYear())
            : (exportObj['ingewisseld'] = '');
          exportObj['bon'] = element.exchangedVoucher
            ? (exportObj['bon'] = element.exchangedVoucher)
            : (exportObj['bon'] = '');

          console.log('exportObj', exportObj);
          exportArray.push(exportObj);

          if (index === discount.previousExchanges.length - 1) {
            console.log('done with array');
            exportObj = {};
            exportObj['code'] = discount.code;
            exportObj['waarde'] = discount.value;
            exportObj['aantal beschikbaar'] = discount.available;
            exportObj['bon'] = '';
            exportObj['ingewisseld'] = '';
            console.log('exportObj', exportObj);
            exportArray.push(exportObj);
          }
        });
      });
    });
    if (exportArray.length > 0) {
      console.log('exportArray', exportArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Kortingscodes');

      // /* save to file */
      XLSX.writeFile(wb, 'Kortingscodes' + '.xlsx');
    } else {
      this.snackBar.open('Er zijn geen kortingscodes in deze bongroep.', 'X', {
        duration: 5000,
      });
    }
  }
}
