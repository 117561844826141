import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-sepa-confirmation',
  templateUrl: './sepa-confirmation.component.html',
  styleUrls: ['./sepa-confirmation.component.scss'],
})
export class SepaConfirmationComponent implements OnInit {
  items: string[] = [];
  confirmationFormGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SepaConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const sepaChecked = this.data.sepaChecked;
    const excelChecked = this.data.excelChecked;
    // const markVouchersAsPaid = this.data.markVouchersAsPaid;

    this.confirmationFormGroup = this.fb.group({
      excel: [excelChecked],
      sepa: [sepaChecked],
      // markVouchersAsPaid: [markVouchersAsPaid],
    });
    console.log('this.data', this.data);
    // if (markVouchersAsPaid) {
    //   this.items.push('Bonnen worden gemarkeerd als betaald.');
    // } else {
    //   this.items.push('Bonnen worden niet gemarkeerd als betaald.');
    // }

    if (excelChecked) {
      this.items.push('Er wordt een Excel bestand aangemaakt.');
    } else {
      this.items.push('Er wordt geen Excel bestand aangemaakt.');
    }

    if (sepaChecked) {
      this.items.push('Er wordt een SEPA bestand aangemaakt.');
    } else {
      this.items.push('Er wordt geen SEPA bestand aangemaakt.');
    }
  }

  save() {
    this.dialogRef.close(true);
  }
}

// set all vouchers paidDate to date of today use collectionGroup with paymentReference
