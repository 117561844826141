import {
  Component,
  Inject,
  Injectable,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  Organisation,
  PaymentReference,
  PaymentReferences,
  Township,
  Voucher,
} from 'src/app/interfaces';
import { MatSelectionList } from '@angular/material/list';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SepaConfirmationComponent } from '../sepa-confirmation/sepa-confirmation.component';
import * as XLSX from 'xlsx';
import * as SEPA from 'sepa';
import { FileSaverService } from 'ngx-filesaver';
import * as xml2js from 'xml2js';
@Component({
  selector: 'app-sepa',
  templateUrl: './sepa.component.html',
  styleUrls: ['./sepa.component.scss'],
})
export class SepaComponent implements OnInit {
  sepaFormGroup: FormGroup;
  downloadFormGroup: FormGroup;

  organisationsCollection: AngularFirestoreCollection<Organisation>;

  organisations: Observable<Organisation[]>;
  townshipId = localStorage.getItem('township');

  selectTxt: string;
  selectedOrganisations: any[] = [];
  isAllSelected = false;

  dateToday = new Date();
  paymentReferenceId = this.dateToday.toLocaleString('nl-NL').replace(' ', '.');
  allOrginsations: Organisation[] = [];

  loading: boolean;
  checkboxValue: string;
  responseData: any;
  displayedColumns: string[] = ['Organisatie', 'Aantal', 'Bedrag'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  sepaSettingsComplete: boolean;
  env = environment;

  downloadXml: any;
  townshipData: Township;

  @ViewChild('selectedOrganisations', { static: true })
  private allSelected: MatSelectionList;
  @ViewChild('stepper')
  private stepper: MatStepper;
  stepIndex = 0;
  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<SepaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { paymentReference: PaymentReferences },
    private _FileSaverService: FileSaverService // private xml2js: xml2js
  ) {
    if (data.paymentReference) {
      this.stepIndex = 1;
    }
  }

  async ngOnInit() {
    this.sepaFormGroup = this.fb.group({
      selectedOrganisations: [, Validators.required],
      selectAll: [],
    });

    this.downloadFormGroup = this.fb.group({
      paymentReferenceId: [this.paymentReferenceId],
      excel: [true],
      sepa: [],
      // markVouchersAsPaid: [],
    });

    if (this.data.paymentReference) {
      this.paymentReferenceId = this.data.paymentReference.id;
      this.loading = true;
      const paymentReferenceData = (
        await this.db
          .doc(
            `township/${this.townshipId}/paymentReferences/${this.paymentReferenceId}`
          )
          .get()
          .toPromise()
      ).data() as PaymentReferences;
      console.log('paymentReferenceData', paymentReferenceData);
      for (const organisation of paymentReferenceData.organisations) {
        const organisationData = (
          await this.db
            .doc(
              `township/${this.townshipId}/organisations/${organisation.organisationId}`
            )
            .get()
            .toPromise()
        ).data() as Organisation;
        organisationData.id = organisation.organisationId;
        console.log('organisations.id', organisation.organisationId);
        this.selectedOrganisations.push(organisationData);
      }
      console.log('this.selectedOrganisations', this.selectedOrganisations);
      this.createTable(paymentReferenceData);
      this.loading = false;
    } else {
      await this.removePaymentReference();
    }

    this.selectTxt = 'Selecteer alle ondernemers';
    this.organisationsCollection = this.db.collection<Organisation>(
      `/township/${this.townshipId}/organisations/`,
      (ref) => {
        let fsQuery: any = ref;
        fsQuery = fsQuery
          // .where('claimedVouchers', '>', 0)
          // .orderBy('claimedVouchers')
          .orderBy('name', 'asc');

        // fsQuery = fsQuery.orderBy('name'); need to get this fixed for sortering
        return fsQuery;
      }
    );
    this.organisations = this.organisationsCollection.valueChanges({
      idField: 'id',
    });

    this.townshipData = (await (
      await this.db
        .doc(`township/${localStorage.getItem('township')}`)
        .get()
        .toPromise()
    ).data()) as Township;
    this.downloadFormGroup.controls.sepa.setValue(
      this.townshipData.sepaSettingsComplete
    );

    this.organisations.subscribe((organisations) => {
      console.log('organisationss', organisations);
      organisations.forEach((element) => {
        this.allOrginsations.push(element);
      });
    });
  }

  onGroupsChange(event: any) {
    const value = event.option.value;
    console.log('value', value);
    if (value === 'selectAll') {
      if (this.isAllSelected) {
        this.allSelected.deselectAll();
        this.isAllSelected = false;
        this.selectTxt = 'Selecteer alle ondernemers';
        this.selectedOrganisations = [];
      } else {
        this.allSelected.selectAll();
        this.isAllSelected = true;
        this.selectTxt = 'Maak actie ongedaan';
        this.selectedOrganisations = this.allOrginsations;
      }
      return;
    }
    const selecterOrg = this.selectedOrganisations.find((org: Organisation) => {
      if (value.id === org.id) {
        return org;
      }
    });
    if (selecterOrg) {
      this.selectedOrganisations.splice(
        this.selectedOrganisations.indexOf(selecterOrg),
        1
      );
    } else {
      this.selectedOrganisations.push(value);
    }
    console.log('new this.selectedOrganisations', this.selectedOrganisations);
  }

  nextStep() {
    if (this.loading) {
      return;
    }
    if (this.selectedOrganisations.length === 0) {
      this.snackBar.open('Je moet een organisatie selecteren', 'X', {
        duration: 5000,
      });
      return;
    }
    let passThrough = true;
    // console.log('selectedOrganisations', this.selectedOrganisations);
    this.selectedOrganisations.forEach((organisation) => {
      if (!organisation.sepaSettingsComplete) {
        this.snackBar.open(
          `De organisatie ${organisation.name} heeft geen SEPA gegevens ingevuld`,
          'X',
          {
            duration: 5000,
          }
        );
        passThrough = false;
      }
    });
    if (!passThrough) {
      return;
    }
    this.stepper.next();
    this.loading = true;
    console.log('selectedOrganisations', this.selectedOrganisations);
    const postData = {
      selectedOrganisations: this.selectedOrganisations,
      townshipId: this.townshipId,
      setSepaPaymentReference: true,
      paymentReference: this.paymentReferenceId,
      dateToday: this.dateToday,
    };
    console.log('postData', postData);
    const requestUrl = `${environment.functionsUrl}/httpSetPaymentreference`;
    let res: Observable<any>;
    res = this.http.post(requestUrl, postData);
    res.subscribe(async (result) => {
      try {
        console.log('result', result);

        const paymentReferenceRef = this.db.doc<PaymentReferences>(
          `/township/${this.townshipId}/paymentReferences/${result.message}`
        );
        const paymentRef = await (
          await paymentReferenceRef.get().toPromise()
        ).data();

        this.createTable(paymentRef);
        this.loading = false;
      } catch (e) {
        console.error(e);
      }
    });
  }

  previousStep() {
    this.stepper.previous();
  }

  async finish(markAsPaid?) {
    if (this.loading) {
      this.snackBar.open('Even geduld, A.U.B', 'X', {
        duration: 3000,
      });
      return;
    }
    const sepaChecked = this.downloadFormGroup.value.sepa;
    const excelChecked = this.downloadFormGroup.value.excel;
    // const markVouchersAsPaid = this.downloadFormGroup.value.markVouchersAsPaid;
    if (!sepaChecked && !excelChecked) {
      this.snackBar.open('Er is geen methode gekozen', 'X', {
        duration: 5000,
      });
      return;
    }
    console.log('markAsPaid', markAsPaid);
    if (!markAsPaid) {
      await this.downloadFiles(excelChecked, sepaChecked);
    } else {
      const dialogRef = this.dialog.open(SepaConfirmationComponent, {
        width: '425px',
        data: {
          sepaChecked,
          excelChecked,
          // markVouchersAsPaid,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.loading = true;
          const postData = {
            townshipId: this.townshipId,
            paymentReferenceId: this.paymentReferenceId,
          };
          // console.log('postData', postData);
          const requestUrl = `${environment.functionsUrl}/httpFinishPaymentreference`;
          let res: Observable<any>;
          res = this.http.post(requestUrl, postData);
          res.subscribe(async (result) => {
            try {
              console.log('result', result);
              if (result.message === 'succeed') {
                await this.downloadFiles(excelChecked, sepaChecked);
                this.dialogRef.close();
                this.loading = false;
              } else {
                this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                  duration: 5000,
                });
                this.loading = false;
              }
            } catch (e) {
              console.error(e);
              this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                duration: 5000,
              });
              this.loading = false;
            }
          });
        }
      });
    }
  }

  async downloadFiles(excelChecked, sepaChecked) {
    try {
      if (sepaChecked) {
        // create XML file and tranfser SEPA data into XML file
        const xmlToInject = await this.initSepaPayment(
          this.paymentReferenceId,
          this.townshipId
        );
        console.log('xmlToInject', xmlToInject);
        if (xmlToInject) {
          // console.log('xmlToInject string', xmlToInject.toString());
          // const newXml = xmlToInject
          //   .toString()
          //   .replace('encoding="UTF-8"', 'encoding="null"'); // manually add UTF-8. NPM module not setting UTF-8
          await this.createXmlFile(
            xmlToInject,
            `${this.paymentReferenceId}.xml`
          );
        }
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'Er is iets mis gegaan met het genereren van het sepa bestand, probeer het later opnieuw of neem contact op met WeMaron.',
        '',
        {
          duration: 5000,
        }
      );
    }
    if (excelChecked) {
      await this.exportPaymentReference(this.paymentReferenceId);
    }
  }

  toggle(event: any) {
    this.checkboxValue = event.value;
  }

  async exportPaymentReference(paymentReference) {
    // console.log('paymentReference', paymentReference);
    const paymentReferenceRef = this.db.doc(
      `township/${localStorage.getItem(
        'township'
      )}/paymentReferences/${paymentReference}`
    );

    let totalPaidAmount = 0;
    let totalVouchers = 0;
    const paymentReferenceArray = [];
    const paymentReferenceObservalble = await paymentReferenceRef.get();
    await paymentReferenceObservalble.forEach((paymentReference) => {
      // console.log('paymentReference', paymentReference);
      const newPaymentReference = paymentReference.data() as any;
      // const exportObjPaymentReference = {};

      // newPaymentReference.date = newPaymentReference.date
      //   ? new Date(newPaymentReference.date)
      //   : '';

      let organisationObj = {};
      newPaymentReference.organisations.forEach((organitation) => {
        console.log('organisation', organitation);
        organisationObj = {};

        organisationObj['Datum'] = newPaymentReference.date
          ? newPaymentReference.date.toDate().getDate() +
            '-' +
            (newPaymentReference.date.toDate().getMonth() + 1) +
            '-' +
            newPaymentReference.date.toDate().getFullYear()
          : '';
        organisationObj['Betalingskenmerk'] = organitation.id
          ? organitation.id
          : '';

        organisationObj['Organisatie'] = organitation.name
          ? organitation.name
          : '';

        organisationObj['Referentienummer betalingsverplichting'] =
          organitation.paymentRef ? organitation.paymentRef : '';

        organisationObj['IBAN'] = organitation.iban ? organitation.iban : '';

        organisationObj['Totale waarde'] = organitation.totalPaidAmount
          ? organitation.totalPaidAmount
          : 0;

        organisationObj['Totale bonnen'] = organitation.totalVouchers
          ? organitation.totalVouchers
          : 0;

        console.log('organitation', organisationObj);
        // organiationArray.push(organisationObj);
        paymentReferenceArray.push(organisationObj);
        // paymentReferenceArray.push(exportObjPaymentReference);
        // organisationObj = {};
      });

      console.log('newPaymentReference', newPaymentReference);
      totalPaidAmount = newPaymentReference.totalPaidAmount;
      totalVouchers = newPaymentReference.totalVouchers;
      // paymentReferenceArray.push(organiationArray);
      // paymentReferenceArray.push(exportObjPaymentReference);
    });
    if (paymentReferenceArray.length >= 0) {
      const organisationObj = {};
      organisationObj['Datum'] = '';
      organisationObj['Betalingskenmerk'] = '';
      organisationObj['Organisatie'] = '';
      organisationObj['Totale waarde'] = totalPaidAmount ? totalPaidAmount : 0;
      organisationObj['Totale bonnen'] = totalVouchers ? totalVouchers : 0;
      organisationObj['Referentienummer betalingsverplichting'] = '';
      organisationObj['IBAN'] = '';

      console.log('organisationObj', organisationObj);
      paymentReferenceArray.push(organisationObj);

      console.log('vouchers', paymentReferenceArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        paymentReferenceArray
      ); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

      // /* save to file */
      XLSX.writeFile(wb, environment.name + '.' + paymentReference + '.xlsx');
    } else {
      // Nothing to export
      this.snackBar.open(
        'Er gaat iets mis bij het exporteren van de excel.',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  async initSepaPayment(paymentReference: string, townshipId: string) {
    let validData = true;
    const townshipIdData = (
      await this.db.doc(`township/${townshipId}`).get().toPromise()
    ).data() as Township;

    const paymentReferenceData = (
      await this.db
        .doc(`township/${townshipId}/paymentReferences/${paymentReference}`)
        .get()
        .toPromise()
    ).data() as PaymentReferences;

    let invoiceCounter = townshipIdData.invoicesSent
      ? townshipIdData.invoicesSent
      : 0;
    let doc = new SEPA.Document('pain.001.001.03');
    try {
      doc.grpHdr.id = paymentReference;
      doc.grpHdr.created = new Date();
      doc.grpHdr.initiatorName = townshipIdData.name;

      // console.log('townshipData', townshipIdData);
      paymentReferenceData.organisations.forEach(async (organisation: any) => {
        console.log('organisation', organisation);
        // const organisationData = (
        //   await this.db.doc(`township/${townshipId}/organisations/${organisation.id}`).get().toPromise()
        // ).data() as Township;

        this.selectedOrganisations.forEach((selectedOrganisation: any) => {
          let skipOrganisation = false;
          if (organisation.organisationId === selectedOrganisation.id) {
            if (organisation.totalPaidAmount) {
              organisation.totalPaidAmount = Number(
                organisation.totalPaidAmount.toFixed(2)
              );
            }
            if (organisation.totalPaidAmount <= 0) {
              skipOrganisation = true;
            }
            if (!selectedOrganisation.sepaSettingsComplete) {
              validData = false;
              return;
            }
            if (!skipOrganisation) {
              // console.log('selectedOrganisation', selectedOrganisation);
              // sent money (township)
              let info = doc.createPaymentInfo();
              info.requestedExecutionDate = new Date();
              info.debtorIBAN = townshipIdData.invoiceInfo.bankAccount;
              if (townshipIdData.invoiceInfo.bic) {
                info.debtorBIC = townshipIdData.invoiceInfo.bic;
              }
              info.debtorName = townshipIdData.invoiceInfo.name;
              info.debtorId = townshipIdData.invoiceInfo.debtorId;
              doc.addPaymentInfo(info);

              // receives money (organisation)
              let tx = info.createTransaction();
              tx.creditorName = selectedOrganisation.sepaSettings.name;
              tx.creditorIBAN = selectedOrganisation.sepaSettings.iban;
              if (selectedOrganisation.sepaSettings.bic) {
                tx.creditorBIC = selectedOrganisation.sepaSettings.bic;
              }
              tx.mandateId = organisation.organisationId;
              tx.mandateSignatureDate = new Date(); // '2014-02-01'
              tx.amount = organisation.totalPaidAmount;
              tx.remittanceInfo = `Factuur nummer: ${invoiceCounter}`;
              tx.end2endId = `${organisation.organisationId}.Factuur.${invoiceCounter}`;
              info.addTransaction(tx);
              invoiceCounter++;
            }
          }
        });
      });
    } catch (e: any) {
      console.error(e);
    }

    this.db.doc(`township/${localStorage.getItem('township')}`).set(
      {
        invoicesSent: invoiceCounter,
      },
      { merge: true }
    );
    if (validData) {
      console.log('doc', doc);
      return doc;
    } else {
      this.snackBar.open(
        'Controleer je SEPA gegevens en/of de bonnen geen 0 waarde hebben',
        'X',
        {
          duration: 5000,
        }
      );
      return false;
    }
  }

  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }

  async createXmlFile(data, fileName) {
    console.log('data', data);
    console.log('filename', fileName);
    const xmlBuilder = new xml2js.Builder();
    const newXml = xmlBuilder.buildObject(data);
    console.log('newXml', newXml);
    this._FileSaverService.save(new Blob([newXml]), fileName);
  }

  async removePaymentReference() {
    this.loading = true;
    const requestUrl = `${environment.functionsUrl}/httpDeletePaymentReference`;
    console.log('requestUrl', requestUrl);
    let res: Observable<any>;
    const postData = {
      townshipId: this.townshipId,
      paymentReference: this.paymentReferenceId,
      // selectedOrganisations: this.selectedOrganisations,
    };
    console.log('postData', postData);
    res = this.http.post(requestUrl, postData);
    res.subscribe(async (result) => {
      this.loading = false;
      console.log('result', JSON.stringify(result));
    });
  }

  async createTable(paymentRef: PaymentReferences) {
    const tableArray = [];
    paymentRef.organisations.forEach((organisation) => {
      const newObj = {} as any;
      newObj.Organisatie = organisation.name;
      newObj.Aantal = organisation.totalVouchers;
      newObj.Bedrag = organisation.totalPaidAmount;
      tableArray.push(newObj);
    });

    let totalVouchers = 0;
    let totalAmount = 0;
    tableArray.forEach((obj) => {
      totalAmount += obj.Bedrag;
      totalVouchers += obj.Aantal;
    });
    tableArray.push({
      Organisatie: 'Totaal',
      Bedrag: totalAmount,
      Aantal: totalVouchers,
    });
    this.responseData = tableArray;
  }
}
