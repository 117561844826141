import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { environment } from "../../environments/environment";
import { Organisation, Township, User } from "../interfaces";
// import { Township, TownshipUser, User } from '../interfaces';
import { firestore } from "firebase";

import { SendVoucherComponent } from "../voucher-management/send-voucher/send-voucher.component";
import { ManageVoucherComponent } from "../voucher-management/manage-voucher/manage-voucher.component";
import { UpgradeDialogComponent } from "./dialogs/upgrade-dialog/upgrade-dialog.component";
import { ConfirmationComponent } from "../voucher-management/dialogs/confirmation-code/confirmation-code.component";
import { isThisSecond } from "date-fns";
import { AdminDialogComponent } from "../dashboard-township/dialog/admin-dialog/admin-dialog.component";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userId: string;
  user: Observable<User>;
  userData: User;
  townshipDoc: AngularFirestoreDocument<Township>;
  $township: Observable<Township>;
  township: Township;
  fixed = true;
  envName: string;
  // select_entity: boolean;
  userEmail: string;
  avatarUrl: string;
  isAdmin = false;
  townshipName: string;
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async logout() {
    console.log("userId", this.userId);
    if (this.isAdmin) {
      await this.afs.doc(`users/${this.userId}`).set(
        {
          township: firestore.FieldValue.delete(),
        },
        { merge: true }
      );
      localStorage.removeItem("township");
    }
    await this.afAuth.signOut();
    return this.route.navigate(["/login"]);
  }

  async openSendVoucherDialog() {
    console.log("User pressed button");
    if (!this.userData) {
      return; // err
    }
    this.dialog.open(SendVoucherComponent, {
      width: "373px",
      // data: { townshipId: this.userData.township },
    });
  }
  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: "373px",
      data: { type },
    });
  }
  openDiscountDialog(): void {
    this.dialog.open(ConfirmationComponent, {
      width: "373px",
      // data: { type },
    });
  }
  showUpdate() {
    const dialogRef = this.dialog.open(UpgradeDialogComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }

  changeTownship(userId: string) {
    const dialogRef = this.dialog.open(AdminDialogComponent, {
      width: "475px",
      // data: { type },
    });
    dialogRef.afterClosed().subscribe(async (townshipId) => {
      if (townshipId) {
        localStorage.setItem("township", townshipId);
        this.townshipDoc = this.afs.doc<Township>("township/" + townshipId);
        console.log("townshipId", townshipId);

        await this.afs.doc(`users/${userId}`).set(
          {
            township: townshipId,
          },
          { merge: true }
        );
        // this.township = this.townshipDoc.valueChanges();
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    console.log("evn", this.env.env);
    if (this.env.env === "township") {
      this.envName = "Beheerder";
    } else {
      this.envName = "Ondernemer";
    }
    this.afAuth.user.subscribe((user) => {
      if (user) {
        // console.log('user', user);
        this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>("users/" + user.uid);
        this.userId = user.uid;
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(async (res) => {
          console.log("UserData =>", res);
          this.userData = res;
          console.log("userData", this.userData);
          if (this.userData.rights === "admin") {
            const townshipId = localStorage.getItem("township");
            console.log("townshipId", townshipId);
            if (townshipId !== "undefined") {
              const township = (
                await this.afs.doc(`township/${townshipId}`).get().toPromise()
              ).data() as Township;
              if (township) {
                console.log("township", township);
                this.townshipName = township.name;
                this.isAdmin = true;
              }
            }
          }
          if (this.userData.township) {
            this.townshipDoc = this.afs.doc<Township>(
              `township/${this.userData.township}`
            );
            this.$township = this.townshipDoc.valueChanges();
            this.$township.subscribe(async (value) => {
              this.township = value;
              console.log("township", this.township);
              if (this.userData.organisation && this.env.env !== "township") {
                const organisation = (
                  await this.afs
                    .doc(
                      `township/${this.userData.township}/organisations/${this.userData.organisation}`
                    )
                    .get()
                    .toPromise()
                ).data() as Organisation;
                if (organisation.useHeaderImage) {
                  this.avatarUrl = organisation.headerImageUrl;
                }
              }
              if (this.env.env === "township" && this.userData.township) {
                if (this.township.useHeaderImage) {
                  this.avatarUrl = this.township.headerImageUrl;
                }
              }
            });
            if (
              (this.env.env === "township" &&
                (!this.userData.township || this.userData.organisation)) ||
              (this.env.env === "organisation" &&
                (!this.userData.township || !this.userData.organisation))
            ) {
              // Sign user out if it's an organisation account in township env
              this.logout();
              this.snackBar.open(
                "Je hebt geen rechten om in deze omgeving in te loggen.",
                "",
                {
                  duration: 10000,
                }
              );
            }

            if (
              this.env.env !== "township" &&
              this.userData.township &&
              !this.userData.organisation
            ) {
              console.log("beheerder logt in op ondernemer", this.userData);
              this.logout();
              this.snackBar.open(
                "Een beheerder kan niet inloggen op een ondernemers omgeving",
                "X",
                {
                  duration: 5000,
                }
              );
            }
          }
        });
        console.log("environment.name", environment.name);
      }
    });
    // this.afAuth.auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     console.log("user =>", user.email)
    //     this.checkUser = true;
    //   } else {
    //     // User is signed out.
    //     // ...
    //   }
    // });
  }
}
