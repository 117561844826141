import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { TownshipUser } from '../interfaces';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { ManageUserComponent } from './dialogs/manage-user/manage-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  users: Observable<TownshipUser[]>;
  displayedColumns = ['email', 'rights', 'buttons'];
  // displayedColumns = ['email', 'rights'];

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.users = db
      .collection<TownshipUser>(`township/${this.townshipId}/users`, (ref) =>
        ref.orderBy('email')
      )
      .valueChanges({ idField: 'id' });
    this.users.subscribe((users) => {
      console.log('users', users);
    });
  }

  ngOnInit(): void {}

  editUser(user?: TownshipUser) {
    if (user && user.rights == 'owner') {
      this.snackBar.open(
        'Je kan de rechten van de eigenaar niet aanpassen.',
        'X',
        {
          duration: 3000,
        }
      );
      return;
    }
    this.dialog.open(ManageUserComponent, {
      width: '475px',
      data: { user },
    });
  }

  deleteUser(user: TownshipUser) {
    if (user && user.rights == 'owner') {
      this.snackBar.open('Je kan de eigenaar niet verwijderen.', 'X', {
        duration: 3000,
      });
      return;
    }
    this.dialog.open(DeleteUserComponent, {
      width: '475px',
      data: { user },
    });
  }
}
