import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { User, TownshipUser, Township } from "../interfaces";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../environments/environment";
import { ManageVoucherComponent } from "../voucher-management/manage-voucher/manage-voucher.component";
import { AdminDialogComponent } from "./dialog/admin-dialog/admin-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-dashboard-township",
  templateUrl: "./dashboard-township.component.html",
  styleUrls: ["./dashboard-township.component.scss"],
})
export class DashboardTownshipComponent implements OnInit {
  env = environment;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: any;
  townshipDoc: AngularFirestoreDocument<Township>;
  township: Observable<Township>;
  townshipUserDoc: AngularFirestoreDocument<TownshipUser>;
  townshipUser: Observable<TownshipUser>;
  isHelpdeskUser: boolean;

  public chartScheme = { domain: ["#b1e4c8", "#7ad0a1", "#5ac288", "#46b473"] };

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.afAuth.user.forEach(async (user) => {
      console.log("user", user);
      if (user) {
        this.userDoc = this.db.doc<User>("users/" + user.uid);
        const newUser = (await (
          await this.userDoc.get().toPromise()
        ).data()) as User;
        const townshipDoc = this.db.doc<TownshipUser>(
          `township/${newUser.township}/users/${user.uid}`
        );
        const townshipUser = (
          await townshipDoc.get().toPromise()
        ).data() as TownshipUser;
        // this.user.forEach((userDoc) => {
        if (townshipUser && townshipUser.rights === "helpdesk") {
          this.isHelpdeskUser = true;
        }
        if (newUser.rights === "admin" && this.env.env === "township") {
          if (
            !newUser.township &&
            localStorage.getItem("township") === "undefined"
          ) {
            let preventDoubleOpening = false;
            if (!preventDoubleOpening) {
              preventDoubleOpening = true;
              const dialogRef = this.dialog.open(AdminDialogComponent, {
                width: "475px",
                data: { mustSelect: true },
                disableClose: true,
                // data: { type },
              });
              dialogRef.afterClosed().subscribe(async (townshipId) => {
                if (townshipId && typeof townshipId !== "undefined") {
                  localStorage.setItem("township", townshipId);
                  this.townshipDoc = this.db.doc<Township>(
                    "township/" + townshipId
                  );
                  this.townshipUserDoc = this.db.doc<TownshipUser>(
                    "township/" + townshipId + "/users/" + user.uid
                  );
                  await this.db.doc(`users/${user.uid}`).set(
                    {
                      township: townshipId,
                    },
                    { merge: true }
                  );
                  this.township = this.townshipDoc.valueChanges();
                }
              });
            }
          }
        }
        if (!newUser.organisation) {
          localStorage.setItem("township", newUser.township);
          console.log("township", newUser.township);
          this.townshipDoc = this.db.doc<Township>(
            "township/" + newUser.township
          );
          this.townshipUserDoc = this.db.doc<TownshipUser>(
            "township/" + newUser.township + "/users/" + user.uid
          );
          this.township = this.townshipDoc.valueChanges();
          this.township.subscribe((township) => {
            // if (township.voucherStatistics) {
            //   this.voucherChartData = [[
            //     township.voucherStatistics.available, township.voucherStatistics.active,
            //     township.voucherStatistics.claimed, township.voucherStatistics.paid
            //   ]];
            //   this.orgChartData = [[
            //     township.organisationStatistics.claimed, township.voucherStatistics.paid
            //   ]];
            // }
          });
        }
        // });
      }
    });
  }

  // openEditVoucherDialog(type: string) {
  //   this.dialog.open(ManageVoucherComponent, {
  //     width: '373px',
  //     data: { type },
  //   });
  // }
}
